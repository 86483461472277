import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import '../css/AdminEditTheorySubjectPage.css'
import { FaArrowLeft } from 'react-icons/fa';
import { API_URL } from "../config";

export default function AdminEditTheorySubjectPage() {
  const { theorySubjectId } = useParams(); // Extract theorySubjectId from URL params
  const navigate = useNavigate(); // Navigation

  const [name, setName] = useState("");

  useEffect(() => {
    // Fetch subject details
    fetch(`${API_URL}/admin/theorySubject/${theorySubjectId}/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setName(data.name);
      })
      .catch(() => {
        alert("Error: Failed to fetch subject details");
      });
  }, [theorySubjectId]);

  const updateSubject = () => {
    if (!name.trim()) {
      alert("Validation Error: Subject name cannot be empty.");
      return;
    }

    fetch(`${API_URL}/admin/theorySubject/${theorySubjectId}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(() => {
        navigate(-1); // Go back to the previous page
      })
      .catch(() => {
        alert("Error: Failed to update subject");
      });
  };

  return (
    <div className="edit-subject-container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1>Edit Subject Theory</h1>
      <input
        type="text"
        placeholder="Subject Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="text-input"
      />
      <button onClick={updateSubject} className="submit-button">
        Update Subject
      </button>
      
    </div>
  );
}
