import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../config';
import Logo from '../assets/icons/LiteIcon.png'
import '../css/ProfilePage.css';
import { FaArrowLeft } from 'react-icons/fa';

const ProfilePage = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState({
    user: {
      username: '',
      email: '',
      country: '',
      is_afliate: '',
    },
  });
  const [editMode, setEditMode] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const [newCountry, setNewCountry] = useState('');
  const [authToken, setAuthToken] = useState('');
  const [coinNum, setCoinNum] = useState(null);
  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [changePasswordMode, setChangePasswordMode] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const fetchCountries = useCallback(async (token) => {
    try {
      const response = await fetch(`${API_URL}/admin/countries/`, {
        headers: { Authorization: `Bearer ${token.access}` },
      });
      const data = await response.json();
      if (response.ok) {
        setCountries(data);
        return data; // Return the fetched countries
      } else {
        console.error('Failed to fetch countries');
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  }, []);
  
  const fetchStudentProfile = useCallback(async (token, countryList) => {
    try {
      const response = await fetch(`${API_URL}/student/profile/`, {
        headers: { Authorization: `Bearer ${token.access}` },
      });
      const data = await response.json();
      if (response.ok) {
        const countryName = countryList.find((c) => c.id === data.country)?.name || data.country;
        setProfile({ ...data, countryName });
        setCoinNum(data.coin_num);
      } else {
        console.error('Error fetching student profile');
      }
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  }, []);
  
  useEffect(() => {
    const retrieveAuthToken = async () => {
      const tokenString = localStorage.getItem('authToken');
      if (tokenString) {
        const token = JSON.parse(tokenString);
        setAuthToken(token);
        const countryList = await fetchCountries(token); // Fetch countries first
        fetchStudentProfile(token, countryList); // Then fetch the profile
      }
    };
    retrieveAuthToken();
  }, [fetchCountries, fetchStudentProfile]);

  const updateProfile = async () => {
    if (!newUsername || !newCountry) {
      alert('Please fill in all required fields.');
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetch(`${API_URL}/student/profile/`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${authToken.access}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: newUsername, country: newCountry }),
      });
      if (response.ok) {
        alert('Profile updated successfully');
        setEditMode(false);
        fetchStudentProfile(authToken);
      } else {
        alert('Failed to update profile');
      }
    } catch (error) {
      alert('Error updating profile');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePassword = async () => {
    if (!oldPassword || !newPassword) {
      alert('Please fill in all required fields.');
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetch(`${API_URL}/change-password/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken.access}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ old_password: oldPassword, new_password: newPassword }),
      });
      if (response.ok) {
        alert('Password changed successfully');
        setChangePasswordMode(false);
        setOldPassword('');
        setNewPassword('');
      } else {
        alert('Failed to change password');
      }
    } catch (error) {
      alert('Error changing password');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="profile-container">
      <button onClick={() => navigate(-1)} className="back-button">
        <FaArrowLeft className="white-arrow" />
      </button>
      <img src={Logo} alt="Logo" className="profile-logo" />
      <h1>Profile</h1>
      <p>Coins: {coinNum}</p>
      <div className="profile-section">
        <div className="profile-item">
          <strong>Username:</strong> {profile.user.username}
        </div>
        <div className="profile-item">
          <strong>Email:</strong> {profile.user.email}
        </div>
        <div className="profile-item">
          <strong>Country:</strong> {profile.countryName || profile.user.country}
        </div>
        {editMode ? (
          <div>
            <input
              type="text"
              value={newUsername}
              onChange={(e) => setNewUsername(e.target.value)}
              placeholder="New Username"
            />
            <select value={newCountry} onChange={(e) => setNewCountry(e.target.value)}>
              <option value="">Select Country</option>
              {countries.map((c) => (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              ))}
            </select>
            <button className="profie-button" onClick={updateProfile} disabled={isLoading}>
              {isLoading ? 'Loading...' : 'Save'}
            </button>
          </div>
        ) : (
          <button className="profie-button" onClick={() => setEditMode(true)}>Edit</button>
        )}
      </div>
      {changePasswordMode ? (
        <div>
          <input
            type="password"
            placeholder="Old Password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
          <input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <button className="profie-button" onClick={handleChangePassword} disabled={isLoading}>
            {isLoading ? 'Loading...' : 'Save'}
          </button>
        </div>
      ) : (
        <button className="profie-button" onClick={() => setChangePasswordMode(true)}>Change Password</button>
      )}
      {profile.is_afliate && (
        <div>
          <button className="profie-button" onClick={() => navigate('/promo-code')}>Promo Code</button>
          <button className="profie-button" onClick={() => navigate('/students')}>Students</button>
          <button className="profie-button" onClick={() => navigate('/delete-account')}>Delete Profile</button>
        </div>
      )}
    </div>
  );
};

export default ProfilePage;
