import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../css/AdminExamPage.css';
import { FaArrowLeft } from 'react-icons/fa';
import { API_URL } from '../config';

const ExamItem = React.memo(({ item, navigateToSubject, theory, editExam }) => (
  <div className="admin-exam-exam-item">
    <button onClick={() => navigateToSubject(item.id)} className="admin-exam-exam-button">
      Name: {item.name}, PostUtme: {item.is_postUTME.toString()}.
    </button>
    <button onClick={() => theory(item.id)} className="admin-exam-exam-button">Theory</button>
    <button onClick={() => editExam(item.id)} className="admin-exam-exam-button">Edit</button>
    {/* <button onClick={() => deleteExam(item.id)} className="admin-exam-delete-button">Delete</button> */}
  </div>
));

export default function AdminExamPage() {
  const { countryId } = useParams();
  const navigate = useNavigate();
  const [exams, setExams] = useState([]);
  const [name, setName] = useState('');
  const [ispostUTME, setIsPostUTME] = useState(false);
  const [examImage, setExamImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchExams = useCallback(() => {
    fetch(`${API_URL}/admin/exams/${countryId}/`)
      .then(response => response.json())
      .then(data => {
        setExams(data.map(exam => ({
          ...exam,
          country: { name: exam['country__name'] }
        })));
      })
      .catch(() => alert('Failed to fetch exams.'));
  }, [countryId]);

  useEffect(() => {
    fetchExams();
  }, [fetchExams]);

  const addExam = () => {
    if (!name) {
      alert('Exam name is required.');
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append('name', name);
    if (examImage) {
      formData.append('exam_img', examImage);
    } 
    formData.append('ispostUTME', ispostUTME.toString());

    
    console.log('ispostUTME:', ispostUTME);
    

    fetch(`${API_URL}/admin/exams/${countryId}/`, {
      method: 'POST',
      body: formData,
    })

      .then(response => response.json())
      .then(newExam => {
        setExams([newExam, ...exams]);
        setName('');
        setExamImage(null);
        setIsPostUTME(false);
      })
      .catch(() => alert('Failed to add exam.'))
      .finally(() => setIsLoading(false));
  };

  const theory = (examId) => {
    navigate(`/admin-theory-subject/${examId}`);
  };

  const editExam = (examId) => {
    navigate(`/admin-edit-exam/${examId}`);
  };

  // const deleteExam = (examId) => {
  //   if (window.confirm('Are you sure you want to delete this exam?')) {
  //     fetch(`${API_URL}/admin/exam/${examId}/`, { method: 'DELETE' })
  //       .then(() => setExams(exams.filter(exam => exam.id !== examId)))
  //       .catch(() => alert('Failed to delete exam.'));
  //   }
  // };

  const toggleIsPostUTME = () => {
    console.log('Toggling ispostUTME:', !ispostUTME);
    setIsPostUTME(prevState => !prevState);
  };

  const navigateToSubject = (examId) => {
    navigate(`/admin-subject/${examId}`);
  };

  const pickImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      setExamImage(e.target.files[0]);
    }
  };

  return (
    <div className="admin-exam-container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1>Exams</h1>
      
      <input
        type="text"
        placeholder="Exam Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="admin-exam-text-input"
      />
      <div className="admin-exam-toggle-container">
        <input
          type="checkbox"
          checked={ispostUTME}
          onChange={toggleIsPostUTME}
          className="admin-exam-checkbox"
        />
        <label className="admin-exam-checkbox-label">Is PostUtme</label>
      </div>
      {examImage && <img src={URL.createObjectURL(examImage)} alt="Preview" className="admin-exam-image-preview" />}
      <input type="file" onChange={pickImage} className="admin-exam-file-input" />
      <button onClick={addExam} className="admin-exam-button" disabled={isLoading}>
        {isLoading ? 'Loading...' : 'Add Exam'}
      </button>
      <div className="admin-exam-exam-list">
        {exams.map((exam) => (
          <ExamItem
            key={exam.id}
            item={exam}
            navigateToSubject={navigateToSubject}
            theory={theory}
            editExam={editExam}
            // deleteExam={deleteExam}
          />
        ))}
      </div>
    </div>
  );
}
