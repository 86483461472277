import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../css/AdminEditExamOptionPage.css";
import { API_URL } from "../config";
import { FaArrowLeft } from 'react-icons/fa';

export default function AdminEditExamOptionPage() {
  const { optionId } = useParams();
  const navigate = useNavigate();

  const [optionLetter, setOptionLetter] = useState("");
  const [text, setText] = useState("");
  const [isAnswer, setIsAnswer] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    fetch(`${API_URL}/admin/exam/option/${optionId}/`)
      .then((response) => response.json())
      .then((data) => {
        setOptionLetter(data.option_letter);
        setText(data.text);
        setIsAnswer(data.is_answer);
        setImageUrl(data.Eoption_img);
      })
      .catch((error) => {
        console.error("Error fetching data:", error); // Log fetch error
      });
  }, [optionId]);

  const updateOption = () => {
    fetch(`${API_URL}/admin/exam/option/${optionId}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ optionLetter, text, isAnswer }),
    })
      .then((response) => response.json())
      .then(() => {
        console.log("Option updated successfully");
        navigate(-1); // Go back to the previous page
      })
      .catch((error) => {
        console.error("Error updating option:", error); // Log update error
      });
  };

  const toggleIsAnswer = () => {
    setIsAnswer((prevState) => !prevState);
  };

  return (
    <div className="admin-edit-container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1>Edit Exam Option</h1>

      <select
        value={optionLetter}
        onChange={(e) => setOptionLetter(e.target.value)}
        className="dropdown"
      >
        <option value="">Select Option Letter</option>
        <option value="A">Option A</option>
        <option value="B">Option B</option>
        <option value="C">Option C</option>
        <option value="D">Option D</option>
      </select>

      <textarea
        placeholder="Option Text"
        value={text}
        onChange={(e) => setText(e.target.value)}
        className="text-input"
      />

      <div className="toggle-container">
        <input
          type="checkbox"
          checked={isAnswer}
          onChange={toggleIsAnswer}
          className="checkbox"
        />
        <label>Is Answer</label>
      </div>

      {imageUrl && (
        <img
          src={imageUrl}
          alt="Option Preview"
          className="image-preview"
          onError={(e) => console.error("Error loading image:", e)}
        />
      )}

      <button className="button" onClick={updateOption}>
        Update Option
      </button>
    </div>
  );
}
