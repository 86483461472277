import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../css/AdminEditExamTestPage.css';
import { API_URL } from '../config';
import { FaArrowLeft } from 'react-icons/fa';

const AdminEditExamTestPage = () => {
  const navigate = useNavigate();
  const { testId } = useParams();
  const [level, setLevel] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch(`${API_URL}/admin/exam/test/${testId}/`)
      .then(response => response.json())
      .then(data => {
        setLevel(data.level.toString());
      })
      .catch(() => alert('Error: Failed to fetch test data.'));
  }, [testId]);

  const updateTest = () => {
    if (!level) {
      alert('Error: Please enter a valid test level.');
      return;
    }

    setLoading(true);
    fetch(`${API_URL}/admin/exam/test/${testId}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ level }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to update test.');
        }
        return response.json();
      })
      .then(() => {
        
        navigate(-1); // Navigate back to the previous page
      })
      .catch(() => alert('Error: Failed to update test level.'))
      .finally(() => setLoading(false));
  };

  return (
    <div className="edit-test-container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1>Edit Exam Level</h1>
      <input
        type="number"
        placeholder="Test Level"
        value={level}
        onChange={e => setLevel(e.target.value)}
        className="text-input"
      />
      <button
        onClick={updateTest}
        disabled={loading}
        className="update-button"
      >
        {loading ? 'Updating...' : 'Update Test'}
      </button>
    </div>
  );
};

export default AdminEditExamTestPage;
