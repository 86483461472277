import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../css/AdminTestOptionPage.css';
import { FaArrowLeft } from 'react-icons/fa';
import { API_URL } from '../config';

export default function AdminTestOptionPage() {
  const { questionId } = useParams();
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [optionLetter, setOptionLetter] = useState('');
  const [text, setText] = useState('');
  const [isAnswer, setIsAnswer] = useState(false);
  const [imageUri, setImageUri] = useState(null);
  const [imagePreview, setImagePreview] = useState(null); // For image preview
  const [loading, setLoading] = useState(true);

  const fetchOptions = useCallback(() => {
    setLoading(true);
    fetch(`${API_URL}/admin/options/${questionId}/`)
      .then((response) => response.json())
      .then((data) => {
        setOptions(data);
        setLoading(false);
      })
      .catch((error) => {
        alert('Failed to fetch options.');
        setLoading(false);
      });
  }, [questionId]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageUri(e);
      setImagePreview(URL.createObjectURL(file)); // Generate preview URL
    }
  };

  const addOption = () => {
    if (!optionLetter || !text) {
      alert('Option Letter and Text are required.');
      return;
    }

    const formData = new FormData();
    formData.append('optionLetter', optionLetter);
    formData.append('text', text);
    formData.append('isAnswer', isAnswer);
    if (imageUri) {
      const file = imageUri.target.files[0];
      formData.append('option_img', file);
    }

    fetch(`${API_URL}/admin/options/${questionId}/`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((newOption) => {
        setOptions([newOption, ...options]);
        setOptionLetter('');
        setText('');
        setIsAnswer(false);
        setImageUri(null);
        setImagePreview(null); // Clear preview after saving
      })
      .catch((error) => {
        alert('Failed to add option.');
      });
  };

  const confirmDeleteOption = (optionId) => {
    if (window.confirm('Are you sure you want to delete this option?')) {
      deleteOption(optionId);
    }
  };

  const deleteOption = (optionId) => {
    fetch(`${API_URL}/admin/option/${optionId}/`, {
      method: 'DELETE',
    })
      .then(() => {
        setOptions(options.filter((option) => option.id !== optionId));
      })
      .catch((error) => {
        alert('Failed to delete option.');
      });
  };

  const toggleIsAnswer = () => {
    setIsAnswer((prevState) => !prevState);
  };

  return (
    <div className="container">
      <button onClick={() => navigate(-1)} className="back-button">
        <FaArrowLeft className="white-arrow" />
        Back
      </button>
      <h1>Manage Options</h1>
      <select
        value={optionLetter}
        onChange={(e) => setOptionLetter(e.target.value)}
        className="picker"
      >
        <option value="">Select Option Letter</option>
        <option value="A">Option A</option>
        <option value="B">Option B</option>
        <option value="C">Option C</option>
        <option value="D">Option D</option>
      </select>
      <textarea
        placeholder="Option Text"
        value={text}
        onChange={(e) => setText(e.target.value)}
        className="textInput"
      />
      <div className="toggleContainer">
        <div
          className={`checkbox ${isAnswer ? 'checkboxSelected' : ''}`}
          onClick={toggleIsAnswer}
        />
        <label className="checkboxLabel">Is Answer</label>
      </div>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        className="fileInput"
      />
      {imagePreview && (
        <div className="image-preview-container">
          <img src={imagePreview} alt="Preview" className="image-preview" />
        </div>
      )}
      <button className="button" onClick={addOption}>
        Add Option
      </button>
      {loading ? (
        <p>Loading options...</p>
      ) : (
        <ul>
          {options.map((option) => (
            <li key={option.id} className="existexam">
              <p className="existexamtext">
                Option: {option.option_letter}, Answer: {option.is_answer ? 'Yes' : 'No'}
              </p>
              <button
                className="button"
                onClick={() => navigate(`/admin-edit-test-option/${option.id}`)}
              >
                Edit
              </button>
              <button
                className="exambutton"
                onClick={() => confirmDeleteOption(option.id)}
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
