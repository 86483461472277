import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../css/AdminExamQuestionPage.css';
import { API_URL } from '../config';
import { FaArrowLeft } from 'react-icons/fa';

export default function AdminExamQuestionPage() {
  const { testId } = useParams();
  const navigate = useNavigate();

  const [questions, setQuestions] = useState([]);
  const [number, setNumber] = useState('');
  const [text, setText] = useState('');
  const [imageUri, setImageUri] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredQuestions, setFilteredQuestions] = useState([]);

  useEffect(() => {
    fetch(`${API_URL}/admin/exam/questions/${testId}/`)
      .then((response) => response.json())
      .then((data) => {
        setQuestions(data);
        setFilteredQuestions(data);
      })
      .catch(() => alert('Failed to fetch questions.'));
  }, [testId]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageUri(e);
      setImagePreview(URL.createObjectURL(file)); // Generate preview URL
    }
  };

  const addQuestion = () => {
    if (!number || !text) {
      alert('Question number and text are required.');
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('number', number);
    formData.append('text', text);
    if (imageUri) {
      const file = imageUri.target.files[0];
      formData.append('Eq_img', file);
    }

    fetch(`${API_URL}/admin/exam/questions/${testId}/`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((newQuestion) => {
        setQuestions([newQuestion, ...questions]);
        setFilteredQuestions([newQuestion, ...questions]);
        setNumber('');
        setText('');
        setImageUri(null);
        setImagePreview(null);
      })
      .catch(() => alert('Failed to add question.'))
      .finally(() => setLoading(false));
  };

  const deleteQuestion = (questionId) => {
    if (window.confirm('Are you sure you want to delete this question?')) {
      fetch(`${API_URL}/admin/exam/question/${questionId}/`, {
        method: 'DELETE',
      })
        .then(() => {
          const updatedQuestions = questions.filter((q) => q.id !== questionId);
          setQuestions(updatedQuestions);
          setFilteredQuestions(updatedQuestions);
        })
        .catch(() => alert('Failed to delete question.'));
    }
  };

  const navigateToOption = (questionId) => {
    navigate(`/admin-exam-options/${ questionId }`);
  };

  const editQuestion = (questionId) => {
    navigate(`/admin-edit-exam-question/${ questionId }`);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query === '') {
      setFilteredQuestions(questions);
    } else {
      setFilteredQuestions(
        questions.filter((q) => q.number.toString().includes(query))
      );
    }
  };

  return (
    <div className="admin-container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1>Exam Questions</h1>
      <div className="form-group">
        <input
          type="number"
          placeholder="Question Number"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
        />
        <textarea
          placeholder="Question Text"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <input type="file" onChange={handleImageChange} />
        {imagePreview && <img src={imagePreview} alt="Preview" className="image-preview" />}
        <button onClick={addQuestion} disabled={loading}>
          {loading ? 'Adding...' : 'Add Question'}
        </button>
      </div>

      <div className="form-group">
        <input
          type="text"
          placeholder="Search by Question Number"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>

      <div className="question-list">
        {filteredQuestions.map((q) => (
          <div key={q.id} className="question-item">
            <div className="question-details">
              <p>Number: {q.number}</p>
              <p>{q.text}</p>
            </div>
            <div className="actions">
              <button className='button' onClick={() => navigateToOption(q.id)}>option</button>
              <button className='button' onClick={() => editQuestion(q.id)}>Edit</button>
              <button className='delete-button' onClick={() => deleteQuestion(q.id)}>Delete</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
