import React, { useState, useEffect, useCallback  } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../css/AdminExamTestPage.css';
import { API_URL } from '../config';
import { FaArrowLeft } from 'react-icons/fa';

const AdminExamTestPage = () => {
  const navigate = useNavigate();
  const { subjectId } = useParams();

  const [tests, setTests] = useState([]);
  const [level, setLevel] = useState('');

  const fetchTests = useCallback(() => {
    fetch(`${API_URL}/admin/exam/tests/${subjectId}/`)
      .then(response => response.json())
      .then(data => setTests(data))
      .catch(() => alert('Error: Failed to fetch tests.'));
  }, [subjectId]);

  useEffect(() => {
    fetchTests();
  }, [fetchTests]);

  const addTest = () => {
    if (!level) {
      alert('Validation Error: Test level is required.');
      return;
    }

    fetch(`${API_URL}/admin/exam/tests/${subjectId}/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ level }),
    })
      .then(response => response.json())
      .then(newTest => {
        setTests([newTest, ...tests]);
        setLevel('');
      })
      .catch(() => alert('Error: Failed to add test.'));
  };

  const editTest = testId => {
    navigate(`/admin-edit-exam-test/${testId}`);
  };

  // const deleteTest = testId => {
  //   if (window.confirm('Are you sure you want to delete this test level?')) {
  //     fetch(`${API_URL}/admin/exam/test/${testId}/`, {
  //       method: 'DELETE',
  //     })
  //       .then(() => setTests(tests.filter(test => test.id !== testId)))
  //       .catch(() => alert('Error: Failed to delete test.'));
  //   }
  // };

  const navigateToQuestion = testId => {
    navigate(`/admin-exam-questions/${testId}`);
  };

  return (
    <div className="container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1>Exam Levels</h1>
      <div className="form-group">
        <input
          type="text"
          placeholder="Test Level"
          value={level}
          onChange={e => setLevel(e.target.value)}
          className="text-input"
        />
        <button onClick={addTest} className="button">
          Add Level
        </button>
      </div>
      <div className="test-list">
        {tests.map(test => (
          <div key={test.id} className="test-item">
            <div className="test-info" onClick={() => navigateToQuestion(test.id)}>
              <p>Level: {test.level}</p>
            </div>
            <button onClick={() => editTest(test.id)} className="button edit-button">
              Edit
            </button>
            <button className="button delete-button">
              Delete
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminExamTestPage;
