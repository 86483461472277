import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Gold from '../assets/icons/gold.png'
import '../css/GoldPage.css'; // Import your CSS file for styling
import { API_URL } from '../config';
import { FaArrowLeft } from 'react-icons/fa';

const GoldPage = () => {
  const navigate = useNavigate();
  const [numOfCoin, setNumOfCoin] = useState('');
  const [coins, setCoins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [authToken, setAuthToken] = useState('');
  const [email, setEmail] = useState('');
  const [publickey, setPublickey] = useState('');
  const [secretkey, setSecretkey] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    const retrieveAuthToken = async () => {
      try {
        const tokenString = localStorage.getItem('authToken');
        if (tokenString) {
          const token = JSON.parse(tokenString);
          setAuthToken(token);
          fetchCoinsAndEmail(token);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error('Error retrieving token:', error);
        setLoading(false);
      }
    };

    retrieveAuthToken();
  }, []);

  const fetchCoinsAndEmail = async (token) => {
    try {
      setLoading(true);
      setError(false);

      const coinsResponse = await axios.get(`${API_URL}/coins/`, {
        headers: { Authorization: `Bearer ${token.access}` },
      });
      setCoins(coinsResponse.data);

      const emailResponse = await axios.get(`${API_URL}/student/profile/`, {
        headers: { Authorization: `Bearer ${token.access}` },
      });
      setEmail(emailResponse.data.user.email);
      setPublickey(emailResponse.data.publickey);
      setSecretkey(emailResponse.data.secretkey);
      setNumOfCoin(emailResponse.data.coin_num);
      

      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(true);
      setLoading(false);
      alert('Failed to load data.');
    }
  };

  const onRefresh = useCallback(() => {
    fetchCoinsAndEmail(authToken);
  }, [authToken]);

  const handleCoinPress = (item) => {
    if (email) {
      console.log(item.amount,item.coins, email, secretkey, publickey)
      navigate('/paystack-payment', {
        state: { amount: item.amount, coins: item.coins, email, secretkey, publickey },
      });
    } else {
      alert('Email not available. Please try again.');
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="loading-container">
        <p className="error-text">Failed to load data.</p>
        <button onClick={onRefresh}>Retry</button>
      </div>
    );
  }

  return (
    <div className="coin-list-container">
      <button onClick={() => navigate(-1)} className="back-button">
          <FaArrowLeft className="white-arrow" />
          
      </button>
      <h1>Available Coins: {numOfCoin}</h1>
      <div className="coin-list">
        {coins.map((item) => (
          <div
            key={item.id}
            className="coin-item"
            onClick={() => handleCoinPress(item)}
          >
            <div className="coin-info">
              <img src={Gold} alt="Coin" className="coin-image" />
              <div className="coin-details">
                <span>{item.coins}</span>
              </div>
            </div>
            <div className="coin-amount">
              <span>
                {item.currency}
                {item.amount}
              </span>
            </div>
          </div>
        ))}
      </div>
      
    </div>
  );
};

export default GoldPage;
