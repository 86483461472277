import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "../css/AdminLocationPage.css";
import { FaArrowLeft } from 'react-icons/fa';
import { API_URL } from "../config";

const AdminLocationPage = () => {
  const { countryId } = useParams(); // Get countryId from route parameters
  const navigate = useNavigate();
  const [locations, setLocations] = useState([]);
  const [state, setState] = useState("");
  const [refreshing, setRefreshing] = useState(false); // Remove if unnecessary

  useEffect(() => {
    const fetchData = async () => {
      setRefreshing(true);
      try {
        const response = await axios.get(`${API_URL}/admin/locations/${countryId}/`);
        setLocations(response.data);
      } catch (error) {
        console.error("Error fetching locations:", error);
      } finally {
        setRefreshing(false);
      }
    };
    fetchData();
  }, [countryId]);

  const createLocation = async () => {
    if (!state) return;
    try {
      const response = await axios.post(`${API_URL}/admin/locations/${countryId}/`, { state });
      setLocations([...locations, response.data]);
      setState("");
    } catch (error) {
      console.error("Error creating location:", error);
    }
  };

  const deleteLocation = async (id) => {
    if (window.confirm("Are you sure you want to delete this location?")) {
      try {
        await axios.delete(`${API_URL}/admin/location/${id}/`);
        setLocations(locations.filter((location) => location.id !== id));
      } catch (error) {
        console.error("Error deleting location:", error);
      }
    }
  };

  return (
    <div className="location-container">
      <div className="header">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
        <h1 className="header-title">Locations</h1>
      </div>
      <div className="input-container">
        <input
          className="location-input"
          type="text"
          placeholder="Enter state"
          value={state}
          onChange={(e) => setState(e.target.value)}
        />
        <button className="add-button" onClick={createLocation}>
          Add Location
        </button>
      </div>
      {refreshing ? (
        <div className="loading-spinner">Loading...</div>
      ) : (
        <div className="location-list">
          {locations.map((item) => (
            <div key={item.id} className="location-item">
              <span>{item.state}</span>
              <button
                className="delete-button"
                onClick={() => deleteLocation(item.id)}
              >
                Delete
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AdminLocationPage;
