import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/AdminNewsListPage.css';
import { FaArrowLeft } from 'react-icons/fa';

import { API_URL } from '../config';

const AdminNewsListPage = () => {
  const { countryId } = useParams();
  const navigate = useNavigate();
  const [newsList, setNewsList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [imageUri, setImageUri] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch news function
  const fetchNews = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/admin/news/${countryId}`);
      setNewsList(response.data);
    } catch (error) {
      console.error('Error fetching news:', error);
    }
  }, [countryId]); // Add `countryId` as a dependency

  // Fetch categories function
  const fetchCategories = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/admin/newscategory/`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  }, []);

  // useEffect for fetching data
  useEffect(() => {
    fetchNews();
    fetchCategories();
  }, [fetchNews, fetchCategories]); // Dependencies are `fetchNews` and `fetchCategories`

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageUri(e);
      setImagePreview(URL.createObjectURL(file)); // Generate preview URL
    }
  };

  const createNews = async () => {
    if (!title || !description || !category) {
      alert('All fields are required.');
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('category', category);
    if (imageUri) {
      const file = imageUri.target.files[0];
      formData.append('news_img', file);
    }

    try {
      const response = await axios.post(`${API_URL}/admin/news/${countryId}/`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setNewsList([...newsList, response.data]);
      setTitle('');
      setDescription('');
      setCategory('');
      setImageUri(null);
      setImagePreview(null);
    } catch (error) {
      console.error('Error creating news:', error);
    } finally {
      setLoading(false);
    }
  };

  const editNews = (newsId) => {
    navigate(`/admin-edit-news/${newsId}`);
  };

  const deleteNews = async (id) => {
    if (window.confirm('Are you sure you want to delete this news item?')) {
      try {
        await axios.delete(`${API_URL}/admin/new/${id}/`);
        setNewsList(newsList.filter((news) => news.id !== id));
      } catch (error) {
        console.error('Error deleting news:', error);
      }
    }
  };
  const handleNavigation = (path) => {
    navigate(path);
  };

  const getCategoryNameById = (id) => {
    const category = categories.find((cat) => cat.id === id);
    return category ? category.name : 'Unknown Category';
  };

  return (
    <div className="container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <button onClick={() => handleNavigation('/admin-news-categories')} className="btn-submit">
          Category
        </button>
      {loading && <div className="loading">Loading...</div>}
      <div className="news-list">
        {newsList.map((item) => (
          <div key={item.id} className="news-item">
            <div>
              <strong>Title:</strong> {item.title} <br />
              <strong>Category:</strong> {getCategoryNameById(item.category)}
            </div>
            <button
              onClick={() => editNews(item.id)}
              className="btn-edit"
            >
              Edit
            </button>
            <button
              onClick={() => deleteNews(item.id)}
              className="btn-delete"
            >
              Delete
            </button>
          </div>
        ))}
      </div>
      <div className="form">
        <input
          type="text"
          placeholder="Enter news title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <textarea
          placeholder="Enter description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <select value={category} onChange={(e) => setCategory(e.target.value)}>
          <option value="">Select category</option>
          {categories.map((cat) => (
            <option key={cat.id} value={cat.id}>
              {cat.name}
            </option>
          ))}
        </select>
        <input type="file" onChange={handleImageUpload} />
        {imagePreview && <img src={imagePreview} alt="Preview" className="image-preview" />}
        <button onClick={createNews} className="btn-submit">
          Create News
        </button>
      </div>
    </div>
  );
};

export default AdminNewsListPage;
