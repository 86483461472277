import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../css/AdminEditTheoryNumberPage.css';
import { API_URL } from '../config';
import { FaArrowLeft } from 'react-icons/fa';

export default function AdminEditTheoryNumberPage() {
  const { theoryNumberId } = useParams(); // Get the `theoryNumberId` from URL params
  const navigate = useNavigate();
  const [number, setNumber] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch the current number for the theory number ID
    fetch(`${API_URL}/admin/theoryNumber/${theoryNumberId}/`)
      .then((response) => response.json())
      .then((data) => setNumber(data.number.toString()))
      .catch(() => alert('Error: Failed to fetch number.'));
  }, [theoryNumberId]);

  const updateNumber = () => {
    if (!number) {
      alert("Number cannot be empty.");
      return;
    }

    setLoading(true);

    fetch(`${API_URL}/admin/theoryNumber/${theoryNumberId}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ number }),
    })
      .then((response) => response.json())
      .then(() => {
        navigate(-1); // Navigate back to the previous page
      })
      .catch(() => alert('Error: Failed to update number.'))
      .finally(() => setLoading(false));
  };

  return (
    <div className="edit-number-container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1>Edit Number</h1>
      <input
        type="number"
        placeholder="Enter Number"
        value={number}
        onChange={(e) => setNumber(e.target.value)}
        className="edit-number-input"
      />
      <button
        onClick={updateNumber}
        disabled={loading}
        className={`edit-number-button ${loading ? 'loading' : ''}`}
      >
        {loading ? 'Updating...' : 'Update Number'}
      </button>
      
    </div>
  );
}
