import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/AdminLocationWithStudentCountPage.css';
import { FaArrowLeft } from 'react-icons/fa';
import { API_URL } from '../config';

const AdminLocationWithStudentCountPage = () => {
  const navigate = useNavigate();

  const [locations, setLocations] = useState([]);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    fetchLocationsWithStudentCounts();
  }, []);

  const fetchLocationsWithStudentCounts = async () => {
    setRefreshing(true);
    try {
      const response = await axios.get(`${API_URL}/admin/locations-with-student-counts/`);
      setLocations(response.data);
    } catch (error) {
      console.error("Error fetching locations:", error);
    } finally {
      setRefreshing(false);
    }
  };

  const navigateToAdminLocationScreen = () => {
    navigate('/admin-location-country');
  };

  return (
    <div className="location-container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <button className="location-button" onClick={navigateToAdminLocationScreen}>
        Go to Location Page
      </button>

      {refreshing ? (
        <p>Loading...</p>
      ) : (
        <ul className="location-list">
          {locations.map((item) => (
            <li key={item.id} className="location-item">
              <span>{item.country_name}, {item.state}</span>
              <span>{item.student_count} students</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AdminLocationWithStudentCountPage;
