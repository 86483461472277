import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // Using useNavigate and useParams for navigation and params
import axios from 'axios';
import { FaArrowLeft } from 'react-icons/fa';
import { API_URL } from '../config';
import '../css/AdminSubjectPage.css'; // Assuming you have a CSS file for styles

export default function AdminSubjectScreen() {
  const navigate = useNavigate();
  const { examId } = useParams(); // Accessing the examId directly from the URL parameters
  const [subjects, setSubjects] = useState([]);
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchSubjects = useCallback(() => {
    fetch(`${API_URL}/admin/subjects/${examId}/`)
      .then(response => response.json())
      .then(data => setSubjects(data))
      .catch(() => alert('Failed to fetch subjects.'));
  }, [examId]);

  const fetchCategories = useCallback(() => {
    axios.get(`${API_URL}/admin/subjectcategory/`)
      .then(response => setCategories(response.data))
      .catch(error => console.log(error));
  }, []);

  useEffect(() => {
    fetchSubjects();
    fetchCategories();
  }, [fetchSubjects, fetchCategories]);

  const addSubject = () => {
    if (!name) {
      alert('Subject name is required.');
      return;
    }

    setLoading(true);

    fetch(`${API_URL}/admin/subjects/${examId}/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name, category })
    })
    .then(response => response.json())
    .then(newSubject => {
      setSubjects([newSubject, ...subjects]);
      setName('');
      setCategory('');
    })
    .catch(() => alert('Failed to add subject.'))
    .finally(() => setLoading(false));
  };

  const editSubject = (subjectId) => {
    navigate(`/admin-edit-subject/${ subjectId }`);
  };

  const navigateToTest = (subjectId) => {
    navigate(`/admin-test/${ subjectId }`);
  };

  const navigateToExamTest = (subjectId) => {
    navigate(`/admin-exam-test/${ subjectId }`);
  };

  return (
    <div className="admin-container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1>Subjects</h1>
      <button onClick={() => navigate('/admin-subject-category')} className="edit-button">
              category page
        </button>
      <input
        type="text"
        placeholder="Subject Name"
        value={name}
        onChange={e => setName(e.target.value)}
        className="text-input"
      />
      <select
        value={category}
        className="category-picker"
        onChange={e => setCategory(e.target.value)}
      >
        <option value="">Select category</option>
        {categories.map((cat) => (
          <option key={cat.id} value={cat.id}>{cat.name}</option>
        ))}
      </select>
      <button onClick={addSubject} disabled={loading} className="button">
        Add Subject
      </button>
      {loading && (
        <div className="loader">Loading...</div>
      )}
      <ul className="subject-list">
        {subjects.map((item) => (
          <li key={item.id} className="subject-item">
            <button onClick={() => navigateToTest(item.id)} className="test-button">
              {item.name}
            </button>
            <button onClick={() => navigateToExamTest(item.id)} className="exam-button">
              Main Exam
            </button>
            <button onClick={() => editSubject(item.id)} className="edit-button">
              Edit
            </button>
            {/* <button className="delete-button">Delete</button> */}
          </li>
        ))}
      </ul>
    </div>
  );
}
