import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/CountryPage.css';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../config';
import { FaArrowLeft } from 'react-icons/fa';

const AdminCountryPage = () => {
  const [countries, setCountries] = useState([]);
  const [name, setName] = useState('');
  const [currency, setCurrency] = useState('');
  const [flag, setFlag] = useState(null);
  const [editingCountry, setEditingCountry] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    try {
      const response = await axios.get(`${API_URL}/admin/countries/`);
      setCountries(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateCountry = async () => {
    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('currency', currency);
      if (flag) {
        formData.append('flag', flag);
      }
      await axios.post(`${API_URL}/admin/countries/`, formData);
      fetchCountries();
      resetForm();
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateCountry = async () => {
    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('currency', currency);
      if (flag) {
        formData.append('flag', flag);
      }
      await axios.put(
        `${API_URL}/admin/countries/${editingCountry.id}/`,
        formData
      );
      fetchCountries();
      resetForm();
    } catch (error) {
      console.error(error);
    }
  };

  const resetForm = () => {
    setName('');
    setCurrency('');
    setFlag(null);
    setEditingCountry(null);
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setFlag(e.target.files[0]);
    }
  };

  return (
    <div className="country-container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1>Countries</h1>
      <input
        type="text"
        placeholder="Country Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="input-field"
      />
      <input
        type="text"
        placeholder="Currency"
        value={currency}
        onChange={(e) => setCurrency(e.target.value)}
        className="input-field"
      />
      {flag && (
        <img
          src={URL.createObjectURL(flag)}
          alt="Country Flag Preview"
          className="image-preview"
        />
      )}
      {!editingCountry && (
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className="file-input"
        />
      )}
      {editingCountry ? (
        <button onClick={handleUpdateCountry} className="btn btn-update">
          Update Country
        </button>
      ) : (
        <button onClick={handleCreateCountry} className="btn btn-create">
          Add Country
        </button>
      )}
      <div className="countries-list">
        {countries.map((country) => (
          <div key={country.id} className="country-item">
            <span>{country.name}, {country.currency}</span>
            <button
              onClick={() => {
                setName(country.name);
                setCurrency(country.currency);
                setEditingCountry(country);
              }}
              className="btn btn-edit"
            >
              Edit
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminCountryPage;
