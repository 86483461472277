import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../css/AdminEditTestPage.css'; // CSS for styling
import { API_URL } from '../config';
import { FaArrowLeft } from 'react-icons/fa';

export default function AdminEditTestPage() {
  const { testId } = useParams(); // Get testId from route parameters
  const navigate = useNavigate();
  const [level, setLevel] = useState('');
  const [loading, setLoading] = useState(false);

  // Fetch test details on component mount
  useEffect(() => {
    fetch(`${API_URL}/admin/test/${testId}/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch test details.');
        }
        return response.json();
      })
      .then((data) => setLevel(data.level.toString()))
      .catch(() => alert('Error: Failed to fetch test details.'));
  }, [testId]);

  // Update test function
  const updateTest = () => {
    setLoading(true);
    fetch(`${API_URL}/admin/test/${testId}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ level }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to update test.');
        }
        return response.json();
      })
      .then(() => {
        
        navigate(-1); // Navigate back
      })
      .catch(() => alert('Error: Failed to update test.'))
      .finally(() => setLoading(false));
  };

  return (
    <div className="admin-edit-container">
      <h1>Edit Test Levels</h1>
      <input
        type="number"
        placeholder="Test Level"
        value={level}
        onChange={(e) => setLevel(e.target.value)}
        className="admin-input"
      />
      <button className="admin-button" onClick={updateTest} disabled={loading}>
        {loading ? 'Updating...' : 'Update Test'}
      </button>
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
    </div>
  );
}
