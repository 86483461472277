import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../css/AdminTestQuestionPage.css';
import { API_URL } from '../config';
import { FaArrowLeft } from 'react-icons/fa';

export default function AdminTestQuestionPage() {
  const { testId } = useParams();
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [number, setNumber] = useState('');
  const [text, setText] = useState('');
  const [solution, setSolution] = useState('');
  const [imageUri, setImageUri] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchQuestions = useCallback(() => {
    fetch(`${API_URL}/admin/questions/${testId}/`)
      .then(response => response.json())
      .then(data => setQuestions(data))
      .catch(() => alert('Error fetching questions.'));
  }, [testId]);

  useEffect(() => {
    fetchQuestions();
  }, [fetchQuestions]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageUri(e);
      setImagePreview(URL.createObjectURL(file)); // Generate preview URL
    }
  };

  const addQuestion = () => {
    if (!number || !text) {
      alert('Question number and text are required.');
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append('number', number);
    formData.append('text', text);
    formData.append('solution', solution);
    if (imageUri) {
      const file = imageUri.target.files[0];
      formData.append('q_img', file);
    }

    fetch(`${API_URL}/admin/questions/${testId}/`, {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(newQuestion => {
        setQuestions([newQuestion, ...questions]);
        setNumber('');
        setText('');
        setSolution('');
        setImageUri(null);
        setImagePreview(null);
      })
      .catch(() => alert('Error adding question.'))
      .finally(() => setIsLoading(false));
  };

  const editQuestion = (questionId) => {
    navigate(`/admin-edit-test-question/${questionId}`);
  };

  const deleteQuestion = (questionId) => {
    if (window.confirm('Are you sure you want to delete this question?')) {
      fetch(`${API_URL}/admin/question/${questionId}/`, {
        method: 'DELETE',
      })
        .then(() => setQuestions(questions.filter(q => q.id !== questionId)))
        .catch(() => alert('Error deleting question.'));
    }
  };

  const navigateToOption = (questionId) => {
    navigate(`/admin-test-options/${questionId}`);
  };

  return (
    <div className="admin-container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1>Manage Questions</h1>

      <div className="form-container">
        <input
          type="number"
          placeholder="Question Number"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
          className="form-input"
        />
        <textarea
          placeholder="Question Text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          className="form-input"
        />
        <textarea
          placeholder="Solution"
          value={solution}
          onChange={(e) => setSolution(e.target.value)}
          className="form-input"
        />
        <input type="file" onChange={handleImageUpload} className="form-input" />
        {imagePreview && <img src={imagePreview} alt="Preview" className="image-preview" />}
        <button onClick={addQuestion} className="form-button" disabled={isLoading}>
          {isLoading ? 'Adding...' : 'Add Question'}
        </button>
      </div>

      <div className="questions-list">
        {questions.map((item) => (
          <div key={item.id} className="question-item">
            <p>Number: {item.number}</p>
            <div className="question-actions">
              <button onClick={() => navigateToOption(item.id)} className="action-button">Options</button>
              <button onClick={() => editQuestion(item.id)} className="action-button">Edit</button>
              <button onClick={() => deleteQuestion(item.id)} className="action-button delete">Delete</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
