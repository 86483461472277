import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../config";
import TestFailedAlertPage from "../common/alert/TestFailedAlertPage";
import TestSuccessAlertPage from "../common/alert/TestSuccessAlertPage";
import '../css/QuestionsPage.css'
import { FaArrowLeft } from 'react-icons/fa';

const QuestionsPage = () => {
  const navigate = useNavigate();
  const { examId, subjectId, testId } = useParams();
  const [state, setState] = useState({
    selectedOptions: {},
    currentQuestionIndex: 0,
    showSuccessAlert: false,
    showErrorAlert: false,
    responseData: {},
    questions: [],
    isLoading: true,
  });

  const fetchToken = () => {
    const tokenString = localStorage.getItem("authToken");
    return tokenString ? JSON.parse(tokenString).access : null;
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const token = fetchToken();
        if (!token) {
          console.error("No authentication token found.");
          return;
        }

        const response = await fetch(
          `${API_URL}/exams/${examId}/subjects/${subjectId}/tests/${testId}/questions`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setState((prev) => ({ ...prev, questions: data, isLoading: false }));
        } else {
          console.error("Failed to fetch questions.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchQuestions();
  }, [examId, subjectId, testId]);

  const handleOptionPress = (questionId, optionId) => {
    setState((prev) => ({
      ...prev,
      selectedOptions: {
        ...prev.selectedOptions,
        [questionId]: optionId,
      },
    }));
  };

  const handleSubmit = async () => {
    try {
      const token = fetchToken();
      if (!token) {
        return;
      }

      const response = await fetch(
        `${API_URL}/submit-answers/${examId}/${subjectId}/${testId}/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ answers: state.selectedOptions }),
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        setState((prev) => ({ ...prev, showSuccessAlert: true, responseData }));
      } else {
        setState((prev) => ({ ...prev, showErrorAlert: true, responseData }));
      }
    } catch (error) {
      console.error("Error submitting answers:", error);
    }
  };

  const { questions, currentQuestionIndex, showSuccessAlert, showErrorAlert, responseData, selectedOptions, isLoading } = state;

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  if (!questions.length) {
    return <div className="no-data">COMING SOON!!!</div>;
  }

  const currentQuestion = questions[currentQuestionIndex];
  const isLastQuestion = currentQuestionIndex === questions.length - 1;

  return (
    <div className="questions-screen">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />
      </button>
      <div className="header">
        <h1>
          Question {currentQuestionIndex + 1} of {questions.length}
        </h1>
      </div>
      <div className="question-card">
        {currentQuestion.q_img && <img src={currentQuestion.q_img} alt="Question" />}
        <p>{currentQuestion.text}</p>
      </div>
      <div className="options">
        {currentQuestion.options.map((option) => (
          <button
            key={option.id}
            onClick={() => handleOptionPress(currentQuestion.id, option.id)}
            className={`option ${selectedOptions[currentQuestion.id] === option.id ? "selected" : ""}`}
          >
            <span>{option.option_letter}:</span>
            <p>{option.text}</p>
            {option.option_img && <img src={option.option_img} alt="Option" />}
          </button>
        ))}
      </div>
      <div className="navigation">
        <button
          onClick={() => setState((prev) => ({ ...prev, currentQuestionIndex: currentQuestionIndex - 1 }))}
          disabled={currentQuestionIndex === 0}
        >
          Previous
        </button>
        <button onClick={isLastQuestion ? handleSubmit : () => setState((prev) => ({ ...prev, currentQuestionIndex: currentQuestionIndex + 1 }))}>
          {isLastQuestion ? "Submit" : "Next"}
        </button>
      </div>

      <TestSuccessAlertPage
        visible={showSuccessAlert}
        title="You passed!"
        message={responseData.message}
        onClose={() => navigate(`/tests/${examId}/${subjectId}`)}
      />

      <TestFailedAlertPage
        visible={showErrorAlert}
        title="Oops!"
        message={responseData.message}
        failedQuestionNumber={responseData.results ? responseData.results.map(result => result.question_number) : []}
        correctAnswer={responseData.results ? responseData.results.map(result => result.correct_options) : []}
        failedAnswer={responseData.results ? responseData.results.map(result => result.selected_options) : []}
        solutions={responseData.results ? responseData.results.map(result => result.solution) : []}
        onClose={() => setState((prev) => ({ ...prev, showErrorAlert: false, selectedOptions: {}, currentQuestionIndex: 0 }))}
      />
    </div>
  );
};

export default QuestionsPage;
