import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../css/AdminTheoryNumberPage.css";
import { API_URL } from "../config";
import { FaArrowLeft } from 'react-icons/fa';

const TestItem = React.memo(({ item, navigateToQuestions, editNumber, deleteNumber }) => (
  <div className="test-item">
    <button className="number-button" onClick={() => navigateToQuestions(item.id)}>
      Number: {item.number}
    </button>
    <button className="edit-button" onClick={() => editNumber(item.id)}>Edit</button>
    <button className="delete-button" onClick={() => deleteNumber(item.id)}>Delete</button>
  </div>
));

export default function AdminTheoryNumberPage() {
  const { theoryYearId } = useParams();
  const navigate = useNavigate();

  const [theoryNumbers, setTheoryNumbers] = useState([]);
  const [number, setNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTheoryNumbers, setFilteredTheoryNumbers] = useState([]);

  const fetchTheoryNumbers = useCallback(() => {
    fetch(`${API_URL}/admin/theoryNumbers/${theoryYearId}/`)
      .then((response) => response.json())
      .then((data) => {
        setTheoryNumbers(data);
        setFilteredTheoryNumbers(data);
      })
      .catch(() => alert("Error fetching theory numbers"));
  }, [theoryYearId]);

  useEffect(() => {
    fetchTheoryNumbers();
  }, [fetchTheoryNumbers]);

  const addTheoryNumber = () => {
    if (!number) {
      alert("Number is required");
      return;
    }

    setLoading(true);

    fetch(`${API_URL}/admin/theoryNumbers/${theoryYearId}/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ number }),
    })
      .then((response) => response.json())
      .then((newTheoryNumber) => {
        setTheoryNumbers([newTheoryNumber, ...theoryNumbers]);
        setFilteredTheoryNumbers([newTheoryNumber, ...theoryNumbers]);
        setNumber("");
      })
      .catch(() => alert("Failed to add number"))
      .finally(() => setLoading(false));
  };

  const editNumber = (theoryNumberId) => {
    navigate(`/admin-edit-theory-number/${theoryNumberId}`);
  };

  const deleteNumber = (theoryNumberId) => {
      fetch(`${API_URL}/admin/theoryNumber/${theoryNumberId}/`, { method: "DELETE" })
        .then(() => {
          const updatedNumbers = theoryNumbers.filter((n) => n.id !== theoryNumberId);
          setTheoryNumbers(updatedNumbers);
          setFilteredTheoryNumbers(updatedNumbers);
        })
        .catch(() => alert("Failed to delete number"));
    
  };

  const navigateToQuestions = (theoryNumberId) => {
    navigate(`/admin-theory-question/${theoryNumberId}`);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (!query) {
      setFilteredTheoryNumbers(theoryNumbers);
    } else {
      setFilteredTheoryNumbers(
        theoryNumbers.filter((n) => n.number && n.number.toString().includes(query))
      );
    }
  };

  return (
    <div className="container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <input
        type="number"
        placeholder="Theory Number"
        value={number}
        onChange={(e) => setNumber(e.target.value)}
        className="input"
      />
      <button className="add-button" onClick={addTheoryNumber} disabled={loading}>
        {loading ? "Loading..." : "Add Number"}
      </button>
      <input
        type="text"
        placeholder="Search"
        value={searchQuery}
        onChange={(e) => handleSearch(e.target.value)}
        className="input"
      />
      <div className="list">
        {filteredTheoryNumbers.map((item) => (
          <TestItem
            key={item.id}
            item={item}
            navigateToQuestions={navigateToQuestions}
            editNumber={editNumber}
            deleteNumber={deleteNumber}
          />
        ))}
      </div>
    </div>
  );
}
