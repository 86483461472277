import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import '../css/NotificationsPage.css';
import {API_URL} from '../config'
import { FaArrowLeft } from 'react-icons/fa';

const NotificationsPage = () => {
  const [notifications, setNotifications] = useState([]);
  
  const [authToken, setAuthToken] = useState(null);
  const navigate = useNavigate();

  const fetchTokenAndCoinNum = async () => {
    try {
      const tokenString = localStorage.getItem('authToken');
      if (tokenString) {
        const token = JSON.parse(tokenString);
        setAuthToken(token);

        const response = await fetch(`${API_URL}/student/profile/`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token.access}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          
        } else {
          console.error('Error fetching coin_num:', response.statusText);
        }
      }
    } catch (error) {
      console.error('Error retrieving token:', error);
    }
  };

  const fetchNotifications = async (token) => {
    try {
      const response = await fetch(`${API_URL}/notifications/`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token.access}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setNotifications(data.notifications);
      } else {
        console.error('Error fetching notifications:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  useEffect(() => {
    if (authToken) {
      fetchNotifications(authToken);
    }
  }, [authToken]);

  useEffect(() => {
    fetchTokenAndCoinNum();
  }, []);

  const renderNotification = (notification) => (
    <div className="notification-container" key={notification.created_at}>
      <p className="notification-message">{notification.message}</p>
      <span className="notification-time">
        {moment(notification.created_at).fromNow()}
      </span>
    </div>
  );

  return (
    <div className="notifications-page">
      <button onClick={() => navigate(-1)} className="back-button">
          <FaArrowLeft className="white-arrow" />
          
      </button>
      <header className="notifications-header">
        
        <h1>Notifications</h1>
        
      </header>

      <div className="notifications-list">
        {notifications.length > 0 ? (
          notifications.map(renderNotification)
        ) : (
          <p className="no-notifications">No notifications available</p>
        )}
      </div>
    </div>
  );
};

export default NotificationsPage;
