import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { API_URL } from '../config';
import '../css/SubjectsPage.css';
import { FaArrowLeft } from 'react-icons/fa';
import Ads1Component from '../ads/Ads1';

const SubjectTheoryPage = () => {
  const navigate = useNavigate();
  const { examId } = useParams();

  const [subjectsData, setSubjectsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchSubjectsData = useCallback(async () => {
    try {
      const tokenString = localStorage.getItem('authToken');
      if (tokenString) {
        const token = JSON.parse(tokenString);

        const response = await fetch(`${API_URL}/theory-subjects/${examId}/subjects`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token.access}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Subjects Data:', data);
          if (data && data.theory_subjects) {
            setSubjectsData(data.theory_subjects);
          } else {
            setSubjectsData([]);
          }
        } else {
          console.error('Error fetching subjects data:', response.statusText);
          setError('Failed to load subjects');
        }
      }
    } catch (error) {
      console.error('Error fetching subjects data:', error);
      setError('Failed to load subjects');
    } finally {
      setIsLoading(false);
    }
  }, [examId]);

  useEffect(() => {
    fetchSubjectsData();
  }, [fetchSubjectsData]);

  const handleSubjectClick = (theorySubjectId) => {
    navigate(`/theory-years/${examId}/${theorySubjectId}`);
  };

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return (
      <div className="error">
        {error}
        <button onClick={() => navigate(-1)} className="back-button">
          <FaArrowLeft className="white-arrow" />
         
        </button>
      </div>
    );
  }

  if (!Array.isArray(subjectsData) || subjectsData.length === 0) {
    return (
      <div className="no-data">
        COMING SOON!!!
        <button onClick={() => navigate(-1)} className="back-button">
          <FaArrowLeft className="white-arrow" />
          Back
        </button>
      </div>
    );
  }

  return (
    <div className="subjects-page">
      <button onClick={() => navigate(-1)} className="back-button">
        <FaArrowLeft className="white-arrow" />
        Back
      </button>
      <div className="suContainer">
        <Ads1Component />
        <button
            className="subject-theory"
            onClick={() => navigate(`/subjects/${examId}`)}
          >
            Go to objective
          </button>
        <main className="subjects-container">
          {subjectsData.map((subject) => (
            <div
              key={subject.id}
              className="subject-item"
              onClick={() => handleSubjectClick(subject.id)}
            >
              {subject.name}
            </div>
          ))}
        </main>
      </div>
    </div>
  );
};

export default SubjectTheoryPage;
