import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/NotFoundPage.css';
import { FaArrowLeft } from "react-icons/fa";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div className="not-found-container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />
      </button>
      <h1 className="not-found-title">404</h1>
      <p className="not-found-message">Oops! The page you're looking for doesn't exist.</p>
      <button className="not-found-button" onClick={() => navigate('/')}>
        Go Back Home
      </button>
    </div>
  );
};

export default NotFoundPage;
