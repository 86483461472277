import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/InstitutionPage.css';
import { API_URL } from '../config';
import { FaArrowLeft } from 'react-icons/fa';

const InstitutionPage = () => {
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState(null);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchTokenAndCoinNum = async () => {
      try {
        const tokenString = localStorage.getItem('authToken');
        if (tokenString) {
          const token = JSON.parse(tokenString);
          setAuthToken(token);
        }
      } catch (error) {
        console.error('Error retrieving token:', error);
      }
    };

    fetchTokenAndCoinNum();
  }, []);

  const fetchExams = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(false);

      const response = await axios.get(`${API_URL}/exams/`, {
        headers: {
          Authorization: `Bearer ${authToken.access}`,
        },
      });

      const filteredData = response.data.exams.filter((exam) => exam.is_postUTME);
      setData(filteredData);
      setFilteredData(filteredData);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching exams:', error);
      setError(true);
      setIsLoading(false);
    }
  }, [authToken]);

  useEffect(() => {
    if (authToken) {
      fetchExams();
    }
  }, [authToken, fetchExams]);

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query === '') {
      setFilteredData(data);
    } else {
      setFilteredData(
        data.filter((exam) => exam.name.toLowerCase().includes(query.toLowerCase()))
      );
    }
  };

  const handleNavigate = (examId) => {
    navigate(`/subjects/${examId}`);
  };

  if (isLoading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <p>Failed to load data.</p>
        <button onClick={fetchExams}>Retry</button>
      </div>
    );
  }

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="institution-container">
      <button onClick={handleBackClick} className="back-button">
            <FaArrowLeft className="white-arrow" />
          </button>
      <header className="institution-header">
        <h1>Institutions</h1>
        
      </header>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search school"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <div className="institutionexam-list">
        {filteredData.length > 0 ? (
          filteredData.map((exam) => (
            <div
              className="institutionexam-card"
              key={exam.id}
              onClick={() => handleNavigate(exam.id)}
            >
              <img src={exam.exam_img} alt={exam.name} className="institutionexam-image" />
              <p className="institutionexam-name">{exam.name}</p>
            </div>
          ))
        ) : (
          <p>No data available</p>
        )}
      </div>
    </div>
  );
};

export default InstitutionPage;
