import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import authImage from '../assets/images/AuthImg.png';
import liteIcon from '../assets/icons/LiteIcon.png';
import '../css/LoginPage.css'; 
import { FaArrowLeft } from 'react-icons/fa';
import { API_URL } from '../config';

const SendPasswordResetEmailPage = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Reset Password Email - LiteUpper';
  }, []);

  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handleSendEmail = async () => {
    if (!email) {
      setError('Email is required.');
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }
  
    setLoading(true);
    setError('');
    try {
      await axios.post(`${API_URL}/send-reset-password/`, { email });
      setSuccess(true);
      setTimeout(() => {
        navigate('/login'); // Redirect to login after success
      }, 50000); // Redirect after 5 seconds
    } catch (error) {
      const message =
        error.response?.data?.message || 'Failed to send email. Please try again.';
      setError(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="image-container">
        <img src={authImage} alt="Authentication Background" className="auth-image" />
        <div className="overlay"></div>
      </div>
      <div className="form-container">
        <div className="login-card">
          <button onClick={handleBackClick} className="back-button">
            <FaArrowLeft className="white-arrow" />
          </button>
          <img src={liteIcon} alt="LiteUpper Icon" className="logo" />
          {success ? (
            <div className="success-message">
              A password reset email has been sent to your email address.
            </div>
          ) : (
            <>
              <input
                type="email"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())}
                className="input"
              />
              {error && <div className="error-message">{error}</div>}
              <button
                onClick={handleSendEmail}
                disabled={loading}
                className="login-button"
              >
                {loading ? 'Sending...' : 'Send Reset Email'}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SendPasswordResetEmailPage;
