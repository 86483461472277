import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../css/AdminTheoryQuestionScreen.css'; // Import CSS styles
import { API_URL } from '../config';
import { FaArrowLeft } from 'react-icons/fa';

// TheoryQuestion Item Component
const TheoryQuestionItem = React.memo(({ item, editTheoryQuestion, deleteTheoryQuestion }) => (
  <div className="theory-question-item">
    <div className="theory-question-text">
      <p>{item.question}</p>
    </div>
    <button className="edit-btn" onClick={() => editTheoryQuestion(item.id)}>Edit</button>
    <button className="delete-btn" onClick={() => deleteTheoryQuestion(item.id)}>Delete</button>
  </div>
));

// Main Admin Theory Question Screen
export default function AdminTheoryQuestionPage() {
  const { theoryNumberId } = useParams();
  const navigate = useNavigate();

  const [theoryQuestions, setTheoryQuestions] = useState([]);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [qImageUri, setQImageUri] = useState(null);
  const [sImageUri, setSImageUri] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [qimagePreview, setQImagePreview] = useState(null);
  const [simagePreview, setSImagePreview] = useState(null);

  // Fetch theory questions
  const fetchTheoryQuestions = useCallback(() => {
    fetch(`${API_URL}/admin/theoryQuestions/${theoryNumberId}/`)
      .then((response) => response.json())
      .then((data) => setTheoryQuestions(data))
      .catch(() => alert('Failed to fetch theory questions.'));
  }, [theoryNumberId]);

  useEffect(() => {
    fetchTheoryQuestions();
  }, [fetchTheoryQuestions]);

  // Handle image upload
  const handle1ImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setQImageUri(e);
      setQImagePreview(URL.createObjectURL(file));
    }
  };

  const handle2ImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSImageUri(e);
      setSImagePreview(URL.createObjectURL(file));
    }
  };


  // Add theory question
  const addTheoryQuestion = () => {
    if (!question || !answer) {
      alert('Both question and answer are required.');
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append('question', question);
    formData.append('answer', answer);

    // Attach question image
    if (qImageUri) {
      const qFile = qImageUri.target.files[0]
      formData.append('quesImg', qFile);
    }

    // Attach answer image
    if (sImageUri) {
      const sFile = sImageUri.target.files[0]
      formData.append('ansImg', sFile);
    }

    fetch(`${API_URL}/admin/theoryQuestions/${theoryNumberId}/`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((newTheoryQuestion) => {
        setTheoryQuestions([newTheoryQuestion, ...theoryQuestions]);
        setQuestion('');
        setAnswer('');
        setQImageUri(null);
        setSImageUri(null);
        setQImagePreview(null); 
        setSImagePreview(null);
      })
      .catch(() => alert('Failed to add theory question.'))
      .finally(() => setIsLoading(false));
  };

  // Edit theory question
  const editTheoryQuestion = (theoryQuestionId) => {
    navigate(`/admin-edit-theory-question/${theoryQuestionId}`);
  };

  // Delete theory question
  const deleteTheoryQuestion = (theoryQuestionId) => {
    if (window.confirm('Are you sure you want to delete this theory question?')) {
      fetch(`${API_URL}/admin/theoryQuestion/${theoryQuestionId}/`, {
        method: 'DELETE',
      })
        .then(() => setTheoryQuestions(theoryQuestions.filter((item) => item.id !== theoryQuestionId)))
        .catch(() => alert('Failed to delete theory question.'));
    }
  };

  return (
    <div className="admin-container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1>Theory Questions</h1>

      <div className="form-section">
        <textarea
          placeholder="Theory Question Text"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          className="text-input"
        />

        {qimagePreview  && <img src={qimagePreview } alt="Preview" className="image-preview" />}
        <label className="upload-label">
          Pick Question Image
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handle1ImageUpload(e, setQImageUri)}
            className="upload-input"
          />
        </label>

        <textarea
          placeholder="Answer"
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          className="text-input"
        />

        {simagePreview && <img src={simagePreview } alt="Preview" className="image-preview" />}
        <label className="upload-label">
          Pick Answer Image
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handle2ImageUpload(e, setSImageUri)}
            className="upload-input"
          />
        </label>

        <button className="submit-btn" onClick={addTheoryQuestion} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Add Theory Question'}
        </button>
      </div>

      <div className="list-section">
        {theoryQuestions.map((item) => (
          <TheoryQuestionItem
            key={item.id}
            item={item}
            editTheoryQuestion={editTheoryQuestion}
            deleteTheoryQuestion={deleteTheoryQuestion}
          />
        ))}
      </div>
    </div>
  );
}
