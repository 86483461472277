import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/AdminNewsEditPage.css';
import { API_URL } from '../config';
import { FaArrowLeft } from 'react-icons/fa';

const AdminNewsEditPage = () => {
  const { newsId } = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchNewsDetails = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/admin/new/${newsId}/`);
      const news = response.data;
      setTitle(news.title);
      setDescription(news.description);
      setCategory(news.category);
      setImage(news.news_img);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching news details:', error);
      setLoading(false);
    }
  }, [newsId]); // Only changes when newsId changes

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_URL}/admin/newscategory/`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    fetchNewsDetails();
    fetchCategories();
  }, [fetchNewsDetails]); // fetchNewsDetails is now a stable dependency

  const updateNews = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${API_URL}/admin/new/${newsId}/`, {
        title,
        description,
        category,
      });
      navigate(-1);
    } catch (error) {
      console.error('Error updating news:', error);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="admin-news-edit-page">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1>Edit News</h1>
      <form onSubmit={updateNews} className="edit-form">
        <div className="form-group">
          <label htmlFor="title">News Title</label>
          <textarea
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter news title"
            rows={2}
          />
        </div>

        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter description"
            rows={4}
          />
        </div>

        <div className="form-group">
          <label htmlFor="category">Category</label>
          <select
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="" disabled>
              Select a category
            </option>
            {categories.map((cat) => (
              <option key={cat.id} value={cat.id}>
                {cat.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Current Image</label>
          <img src={image} alt="News preview" className="image-preview" />
        </div>

        <button type="submit" className="btn-submit">
          Update News
        </button>
      </form>
    </div>
  );
};

export default AdminNewsEditPage;
