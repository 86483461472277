import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/MainNavbar.css';
import { API_URL } from '../config';
import ProfileImage from '../assets/images/profile.png';
import GoldIcon from '../assets/images/gold.png';
import logoImage from '../assets/mainlogo1.png';
import NotiBellImage from '../assets/icons/notibell.jpg';

const MainNavbar = () => {
  const [coinNum, setCoinNum] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const navRef = useRef(null);

  const isValidJson = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch {
      return false;
    }
  };

  useEffect(() => {
    const fetchTokenAndData = async () => {
      try {
        const tokenString = localStorage.getItem("authToken");

        if (tokenString && isValidJson(tokenString)) {
          const token = JSON.parse(tokenString);

          const profileResponse = await fetch(`${API_URL}/student/profile/`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token.access}`,
              "Content-Type": "application/json",
            },
          });

          const contentType = profileResponse.headers.get("content-type");
          if (profileResponse.ok && contentType && contentType.includes("application/json")) {
            const data = await profileResponse.json();
            setCoinNum(data.coin_num);
          } else {
            console.error("Unexpected response format or error:", profileResponse);
          }
        } else {
          console.error("Invalid or missing token in localStorage.");
          localStorage.removeItem("authToken");
          navigate("/login"); // Redirect to login if no valid token
        }
      } catch (error) {
        console.error("Error fetching token or data:", error);
        navigate("/login"); // Redirect to login in case of an error
      }
    };

    fetchTokenAndData();
  }, [navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setMenuOpen(false); // Close menu when clicking outside
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleMenuToggle = (event) => {
    event.stopPropagation(); // Prevent immediate menu closure
    setMenuOpen(prevState => !prevState);
  };

  const handleLogout = () => {
    const confirmLogout = window.confirm("Are you sure you want to logout?");
    if (confirmLogout) {
      localStorage.removeItem('authToken');
      navigate('/login');
    }
  };

  return (
    <nav className="navbar" ref={navRef}>
      <div className="logo">
        <Link to="/">
          <img src={logoImage} alt="Logo" className="logo-image" />
        </Link>
        LiteUpper
      </div>

      <div
        className={"hamburger-icon"}
        onClick={handleMenuToggle}
        aria-expanded={menuOpen}
      >
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </div>

      <ul className={`nav-links ${menuOpen ? 'open' : ''}`}>
        <div style={{ display: 'flex' }}>
          <li className="news">
            <a href="/news">NEWS</a>
          </li>
          <div className="point">.</div>
        </div>
        <li className="news">
          <a href="/home">Home</a>
        </li>
        <button className="coins-button" onClick={() => navigate('/golds')}>
          <img src={GoldIcon} alt="Coins" className="coin-icon" />
          <span className="coin-number">{coinNum !== null ? coinNum : '...'}</span>
        </button>

        <div style={{ display: 'flex' }}>
          <img
            src={NotiBellImage}
            alt="Logo"
            onClick={() => navigate('/notifications')}
            className="noti-image"
          />
          <div className="noti-point">.</div>
        </div>
        <img
          src={ProfileImage}
          alt="Profile"
          className="profile-icon"
          onClick={() => navigate('/profile')}
        />

        <li>
          <button onClick={handleLogout} className="logout-button">
            Logout
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default MainNavbar;
