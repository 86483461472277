import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // For navigation
import '../css/SubjectCategoryPage.css'; // Import CSS for styling
import { FaArrowLeft } from 'react-icons/fa';
import { API_URL } from '../config';

const AdminSubjectCategoryPage = () => {
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState('');
  const navigate = useNavigate();

 

  const fetchCategories = useCallback(() => {
    axios.get(`${API_URL}/admin/subjectcategory/`)
      .then(response => setCategories(response.data))
      .catch(error => console.error('Error fetching categories:', error));
  }, []);

   useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const createCategory = () => {
    if (!name.trim()) {
      alert('Category name is required!');
      return;
    }

    axios.post(`${API_URL}/admin/subjectcategory/`, { name })
      .then(response => {
        setCategories([...categories, response.data]);
        setName('');
      })
      .catch(error => console.error('Error creating category:', error));
  };

  const deleteCategory = (id) => {
    if (window.confirm('Are you sure you want to delete this category?')) {
      axios.delete(`${API_URL}/admin/subjectcategory/${id}/`)
        .then(() => {
          const updatedCategories = categories.filter(category => category.id !== id);
          setCategories(updatedCategories);
        })
        .catch(error => console.error('Error deleting category:', error));
    }
  };

  const editCategory = (categoryId) => {
    navigate(`/admin-edit-category/${categoryId}`);
  };

  return (
    <div className="admin-container">
       <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1>Subject Categories</h1>
      <div className="category-input">
        <input
          type="text"
          placeholder="Enter category name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="text-input"
        />
        <button onClick={createCategory} className="button">Create Category</button>
      </div>
      <div className="category-list">
        {categories.map((item) => (
          <div key={item.id} className="category-item">
            <div className="category-name">{item.name}</div>
            <div className="category-actions">
              <button onClick={() => editCategory(item.id)} className="edit-button">Edit</button>
              <button onClick={() => deleteCategory(item.id)} className="delete-button">Delete</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminSubjectCategoryPage;
