import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/AdminExamCountryPage.css'; // Assuming you have a CSS file for styling
import { FaArrowLeft } from 'react-icons/fa';
import { API_URL } from '../config';

const AdminLocationCountryPage = () => {
  const [countries, setCountries] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    try {
      const response = await axios.get(`${API_URL}/admin/countries/`);
      setCountries(response.data);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const navigateToLocation = (countryId) => {
    navigate(`/admin-location/${countryId}`);
  };

  return (
    <div className="exam-country-container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <header className="exam-country-header">
        <h1>Exams Country</h1>
      </header>
      <div className="examcountries-list">
        {countries.map((country) => (
          <div
            key={country.id}
            className="examcountry-item"
            onClick={() => navigateToLocation(country.id)}
          >
            {country.flag && <img src={country.flag} alt={`${country.name} flag`} className="country-flag" />}
            <p className="country-name">{country.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminLocationCountryPage;
