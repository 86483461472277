import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Use react-router-dom for navigation
import { API_URL } from '../config';
import Logo from '../assets/icons/LiteIcon.png'
import '../css/FirstUpdateProfilePage.css'; // Assuming you use CSS for styling

const FirstUpdateProfilePage = () => {
  const navigate = useNavigate();

  const [country, setCountry] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [countries, setCountries] = useState([]);
  const [authToken, setAuthToken] = useState('');
  const [loading, setLoading] = useState(true);
  

  useEffect(() => {
    const retrieveAuthToken = async () => {
      try {
        const tokenString = localStorage.getItem('authToken');
        if (tokenString) {
          const token = JSON.parse(tokenString);
          setAuthToken(token);
          fetchCountries(token);
        }
      } catch (error) {
        console.error('Error retrieving token:', error);
      }
    };

    retrieveAuthToken();
  }, []);

  const fetchCountries = async (token) => {
    try {
      const response = await fetch(`${API_URL}/admin/countries/`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token.access}`,
          'Content-Type': 'application/json'
        },
      });

      if (response.ok) {
        const data = await response.json();
        setCountries(data);
        setLoading(false);
      } else {
        console.error('Failed to fetch countries');
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const handleUpdate = async () => {
    if (!country) {
      alert('Please fill in all required fields.');
      return;
    }

    const updatedData = {
      country: country,
      promoCode: promoCode,
      is_afliate: false,
    };

    try {
      const response = await fetch(`${API_URL}/student/update/`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${authToken.access}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedData),
      });

      if (response.ok) {
        navigate('/secUpdateProfile');
      } else {
        const data = await response.json();
        if (data && data.error) {
          alert(data.error);
        } else {
          alert('Failed to update profile. Please try again later.');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to update profile. Please try again later.');
    }
  };

  if (loading) {
    return (
      <div className="container">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="container">
      <img src={Logo} alt="Lite Icon" className="uplogo" />

      <label className="label">Country</label>
      <select 
        className="dropdown" 
        value={country} 
        onChange={(e) => setCountry(e.target.value)}
      >
        <option value="">----Select your country----</option>
        {countries.map((country) => (
          <option key={country.id} value={country.id}>
            {country.name}
          </option>
        ))}
      </select>

      <label className="label">You can proceed without ReferralId</label>
      <div className="affiliate-container">
        
      </div>

        <div>
          <label className="label">ReferralId (optional)</label>
          <input
            type="text"
            className="upinput"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
          />
        </div>
  

      <button onClick={handleUpdate} className="button">
        Next
      </button>
    </div>
  );
};

export default FirstUpdateProfilePage;
