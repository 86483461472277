import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import authImage from '../assets/images/AuthImg.png';
import liteIcon from '../assets/icons/LiteIcon.png';
import '../css/LoginPage.css'; 
import { FaArrowLeft } from 'react-icons/fa'; // Import the back arrow icon from react-icons
import { API_URL } from '../config';

const ResetPasswordPage = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Reset Password - LiteUpper';
  }, []);

  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous page
  };


  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');

  const handleResetPassword = async () => {
    if (!password || !confirmPassword) {
      setError('All fields are required.');
      return;
    }
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    setLoading(true);
    setError('');
    try {
      await axios.post(`${API_URL}/reset-password`, {
        new_password: password,
        confirm_password: confirmPassword,
        token,
        
      });
      setSuccess(true);
      setTimeout(() => navigate('/login'), 3000); // Redirect to login after success
    } catch (error) {
      const message =
        error.response?.data?.message || 'Failed to reset password. Please try again.';
      setError(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="image-container">
        <img src={authImage} alt="Authentication Background" className="auth-image" />
        <div className="overlay"></div>
      </div>
      <div className="form-container">
        <div className="login-card">
          <button onClick={handleBackClick} className="back-button">
            <FaArrowLeft className="white-arrow" />
          </button>
          <img src={liteIcon} alt="LiteUpper Icon" className="logo" />
          {success ? (
            <div className="success-message">
              Password reset successful! Redirecting to login...
            </div>
          ) : (
            <>
              <div className="password-container">
                <input
                  type={passwordVisible ? 'text' : 'password'}
                  placeholder="New Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="input"
                />
                <button
                  type="button"
                  className="toggle-password"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  {passwordVisible ? 'Hide' : 'Show'}
                </button>
              </div>
              <div className="password-container">
                <input
                  type={confirmPasswordVisible ? 'text' : 'password'}
                  placeholder="Confirm New Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="input"
                />
                <button
                  type="button"
                  className="toggle-password"
                  onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                >
                  {confirmPasswordVisible ? 'Hide' : 'Show'}
                </button>
              </div>
              {error && <div className="error-message">{error}</div>}
              <button
                onClick={handleResetPassword}
                disabled={loading}
                className="login-button"
              >
                {loading ? 'Resetting...' : 'Reset Password'}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
