import React from 'react';
import '../../css/ExamBeginAlertPage.css';

const ExamBeginAlertPage = ({ visible, title, onClose, onCancel, imageSource }) => {
  if (!visible) return null;

  return (
    <div className="ExamBeginmodal-overlay ">
      <div className="exam-alert">
        <div className="exam-alert-card">
          <h2 className="exam-alert-title">{title}</h2>
          <img src={imageSource} alt="exam" className="exam-alert-image" />
          <p className="exam-alert-message">YOU CAN NOW TAKE EXAM</p>
        </div>

        <div className="exam-details">
          <div className="exam-title">
            <p className="exam-text">EXAM</p>
          </div>
          <div>
            <p className="exam-detail">- Helps you learn more.</p>
            <p className="exam-detail">- Helps you manage time.</p>
            <p className="exam-detail">- Prepares you for real exam.</p>
            <p className="exam-warning">
              Please try and finish before the time because it will automatically submit.
            </p>
          </div>
        </div>

        <div className="exam-buttons">
          <button onClick={onClose} className="exam-btn">
            Take Exam
          </button>
          <button onClick={onCancel} className="exam-btn cancel-btn">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExamBeginAlertPage;
