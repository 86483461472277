import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../css/TheoryYearPage.css';
import { API_URL } from '../config';
import { FaArrowLeft } from 'react-icons/fa';

const TheoryNumbersPage = () => {
    const { theoryYearId } = useParams();
    const navigate = useNavigate();


    const [numbers, setNumbers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch the token and coin number from localStorage
    const fetchTokenAndCoinNum = useCallback(async () => {
        try {
            const tokenString = localStorage.getItem('authToken');
            if (tokenString) {
                const token = JSON.parse(tokenString);

                const response = await fetch(`${API_URL}/student/profile/`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token.access}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    
                    
                } else {
                    console.error('Failed to fetch coin number:', response.statusText);
                }
            }
        } catch (error) {
            console.error('Error fetching token or coin number:', error);
        }
    }, []);

    const fetchNumbers = useCallback(async () => {
        try {
            setIsLoading(true);
            
            const tokenString = localStorage.getItem('authToken');
            if (tokenString) {
                const token = JSON.parse(tokenString);
                
                const response = await fetch(
                    `${API_URL}/theory-numbers/${theoryYearId}/numbers`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token.access}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );
    
                if (response.ok) {
                    const data = await response.json();
                    setNumbers(data.theory_numbers || []);
                } else {
                    setError(`Failed to fetch years data: ${response.statusText}`);
                }
            } else {
                setError('No authentication token found');
            }
        } catch (err) {
            setError(`An error occurred while fetching years data: ${err.message}`);
        } finally {
            setIsLoading(false);
        }
    }, [theoryYearId]);
    

    useEffect(() => {
        fetchTokenAndCoinNum();
        fetchNumbers();
    }, [fetchTokenAndCoinNum, fetchNumbers]);

    const handleYearClick = (theoryNumberId) => {
        navigate(`/theory-questions/${theoryNumberId}`);
    };

  

    if (isLoading) {
        return <div className="year-loading">Loading...</div>;
    }

    if (error) {
        return (
          <div className="year-loading">check your internet connection</div>
            
        );
    }

    if (numbers.length === 0) {
        return <div className="year-no-data">COMING SOON!!! 
        <button onClick={() => navigate(-1)} className="back-button">
          <FaArrowLeft className="white-arrow" />
          
        </button></div>;
    }

    return (
        <div className="year-background-image">
            <button onClick={() => navigate(-1)} className="back-button">
          <FaArrowLeft className="white-arrow" />
          
        </button>
            <div className="year-container">
                <div className="year-header">
                    <h1>Numbers</h1>
                </div>
                <div className="years-list">
                    {numbers.map((number) => (
                        <button
                            key={number.id}
                            className="year-button"
                            onClick={() => handleYearClick(number.id)}
                        >
                            {number.number}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TheoryNumbersPage;
