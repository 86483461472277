import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { API_URL } from '../config';

import ExamSuccessAlert from '../common/alert/ExamSuccessAlertPage';
import GoldErrorAlert from '../common/alert/GoldErrorAlertPage';
import '../css/ExamPage.css';

const ExamPage = () => {
  const navigate = useNavigate();
  const { examId, subjectId, testId } = useParams();

  const [coinNum, setCoinNum] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [timeLeft, setTimeLeft] = useState(50 * 60); 
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchWithToken = async (url, options = {}) => {
    try {
      const tokenString = localStorage.getItem('authToken');
      const token = tokenString ? JSON.parse(tokenString) : null;

      if (!token) {
        throw new Error('Authentication token not found. Please log in again.');
      }

      const response = await fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${token.access}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Something went wrong.');
      }

      return response.json();
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const profileData = await fetchWithToken(`${API_URL}/student/profile/`);
        setCoinNum(profileData.coin_num);

        const questionData = await fetchWithToken(
          `${API_URL}/main/exams/${examId}/subjects/${subjectId}/tests/${testId}/questions`
        );
        setQuestions(questionData);
      } catch (err) {
        console.error('Error fetching data:', err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [examId, subjectId, testId]);

  const handleSubmit = useCallback(async () => {
    if (Object.keys(selectedOptions).length === 0) {
      alert("You must answer at least one question before submitting.");
      return;
    }
    setLoading(true);
    console.log("Payload being sent:", JSON.stringify({ answers: selectedOptions }));
    try {
      const response = await fetchWithToken(
        `${API_URL}/main/submit-answers/${examId}/${subjectId}/${testId}/exam`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ answers: selectedOptions }),
          

        }
        
      );
      
      setResponseData(response);
      setShowSuccessAlert(true);
    } catch (err) {
      console.error('Error submitting answers:', err.message);
      alert(err.message);
    } finally {
      setLoading(false);
    }
  }, [examId, subjectId, testId, selectedOptions]);

  const handleOptionSelect = (questionId, optionId) => {
    setSelectedOptions((prevState) => {
      const updatedState = {
        ...prevState,
        [questionId]: optionId,
      };
      console.log('Updated Selected Options:', updatedState);
      return updatedState;
    });
  };


  useEffect(() => {
    if (timeLeft <= 0) {
      handleSubmit();
    }
    const timer = setInterval(() => setTimeLeft((prev) => Math.max(prev - 1, 0)), 1000);
    return () => clearInterval(timer);
  }, [timeLeft, handleSubmit]);

  
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  if (loading) return <div>Loading...</div>;

  if (error)
    return (
      <GoldErrorAlert
        title="Insulficient Gold!"
        coinNum={coinNum}
        onClose={() => navigate(`/tests/${examId}/${subjectId}`)}
        buyGold={() => navigate('/golds')}
      />
    );

  return (
    <div className="main-exam-screen">
      <div className="timer" style={{ color: timeLeft <= 60 ? 'red' : timeLeft <= 120 ? 'yellow' : 'black' }}>
        Time Left: {formatTime(timeLeft)}
      </div>
      <div className="questions-container">
        {questions.map((question, index) => (
          <div key={question.id} className="question-card">
            <p>Question {index + 1}:</p>
            <p className='questions-text'>{question.text}</p>
            {question.options.map((option) => (
              <button
                key={option.id}
                className={`option ${selectedOptions[question.id] === option.id ? 'selected' : ''}`}
                onClick={() => handleOptionSelect(question.id, option.id)}
              >
                {option.option_letter}. {option.text}
              </button>
            ))}
          </div>
        ))}
      </div>
      <button className="submit-button" onClick={handleSubmit}>
        Submit
      </button>
      <ExamSuccessAlert
        visible={showSuccessAlert}
        title="Score!"
        message={responseData.message}
        totalQuestion={responseData.total_questions}
        correctCount={responseData.correct_count}
        onClose={() => navigate(`/tests/${examId}/${subjectId}`)}
      />
    </div>
  );
};

export default ExamPage;
