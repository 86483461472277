import React, { useEffect, useState } from 'react';
import '../css/EmailVerificationPage.css';
import axios from 'axios';
import { API_URL } from '../config';

const EmailVerificationPage = () => {
  const [emailVerified, setEmailVerified] = useState(null); // Null indicates the status is unknown

  useEffect(() => {
    const verifyEmail = async () => {
      const params = new URLSearchParams(window.location.search);
      const token = params.get('token');

      if (token) {
        try {
          const response = await axios.get(
            `${API_URL}/verify?token=${encodeURIComponent(token)}`
          );
          if (response.status === 200) {
            setEmailVerified(true);
          }
        } catch (error) {
          console.error("Verification failed:", error);
          setEmailVerified(false);
        }
      } else {
        setEmailVerified(false);
      }
    };

    verifyEmail();
  }, []);

  return (
    <div className="email-verification-page">
      <h2>Email Status</h2>
      {emailVerified === null ? (
        <p>Verifying your email...</p>
      ) : emailVerified ? (
        <div className="verification-status verified">
          <div className="status-circle">Active</div>
          <p>Your email is verified!</p>
          <p>You can now login to your app.</p>
        </div>
      ) : (
        <div className="verification-status unverified">
          <div className="status-circle">Pending</div>
          <p>Verification failed. Please try again.</p>
        </div>
      )}
    </div>
  );
};

export default EmailVerificationPage;
