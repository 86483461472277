import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import axios from 'axios';
import '../css/DashboardPage.css'; // Import your CSS file for styling
import { API_URL } from '../config';

const DashboardPage = () => {
  const navigate = useNavigate();
  const [totalUsers, setTotalUsers] = useState(null);
  const [affiliateUsers, setAffiliateUsers] = useState(null);
  const [nonAffiliateUsers, setNonAffiliateUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  

  useEffect(() => {
    const retrieveAuthToken = async () => {
      try {
        const tokenString = localStorage.getItem('authToken'); // Use localStorage for web
        if (tokenString) {
          const token = JSON.parse(tokenString);
          fetchUserCounts(token);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error('Error retrieving token:', error);
        setLoading(false);
      }
    };

    retrieveAuthToken();
  }, []);

  const fetchUserCounts = async (token) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token.access}` },
      };

      const response = await axios.get(`${API_URL}/admin/user-count/`, config);
      setTotalUsers(response.data.total_users);
      setAffiliateUsers(response.data.affiliate_users);
      setNonAffiliateUsers(response.data.non_affiliate_users);
    } catch (error) {
      console.error('Error fetching user counts:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="dashboard-container">
      <h1 className="dashboard-header">Dashboard</h1>
      <div className="user-counts">
        <p>Total Users: {totalUsers}</p>
        <p>Affiliate Users: {affiliateUsers}</p>
        <p>Non-Affiliate Users: {nonAffiliateUsers}</p>
      </div>
      <div className="button-group">
        <button className='Dbutton' onClick={() => handleNavigation('/admin-exam-country')}>Go to Admin Exam Screen</button>
        <button className='Dbutton' onClick={() => handleNavigation('/admin-student')}>Go to Admin Student Screen</button>
        <button className='Dbutton' onClick={() => handleNavigation('/admin-promo-code')}>Go to PromoCode Users</button>
        <button className='Dbutton' onClick={() => handleNavigation('/admin-location-count')}>Go to Location</button>
        <button className='Dbutton' onClick={() => handleNavigation('/admin-country')}>Go to Countries</button>
        <button className='Dbutton' onClick={() => handleNavigation('/admin-gold-country')}>Go to Coins</button>
      </div>
    </div>
  );
};

export default DashboardPage;
