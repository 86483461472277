import React, { useEffect, useState } from 'react';
import { API_URL } from '../config';
import '../css/Ads2Component.css'; // Import the CSS file

const Ads2Component = () => {
  const [ads, setAds] = useState([]);
  const [authToken, setAuthToken] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const isValidJson = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch {
      return false;
    }
  };

  useEffect(() => {
    const fetchAuthToken = () => {
      try {
        const tokenString = localStorage.getItem('authToken');
        if (tokenString && isValidJson(tokenString)) {
          const token = JSON.parse(tokenString);
          setAuthToken(token.access);
        }
      } catch (error) {
        console.error('Error fetching auth token:', error);
        alert('Failed to retrieve authentication token');
      }
    };
    fetchAuthToken();
  }, []);

  useEffect(() => {
    const fetchAds = async () => {
      if (!authToken) return;

      try {
        const response = await fetch(`${API_URL}/ads2/`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setAds(data);
        } else {
          console.error('Error fetching Ads:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching Ads:', error);
      }
    };
    fetchAds();
  }, [authToken]);

  useEffect(() => {
    if (ads.length === 0) return;

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % ads.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [ads]);

  return (
    <div className="ads2-container">
      {ads.length > 0 ? (
        <div className="ads-image-wrapper">
          <img
            src={ads[currentIndex].adsImg}
            alt="Ad"
            className="ads-image"
          />
        </div>
      ) : (
        <p>No ads available</p>
      )}
    </div>
  );
};

export default Ads2Component;
