import React from 'react';
import '../../css/TestFailedAlertPage.css';

const TestFailedAlertPage = ({
  visible,
  title,
  message,
  onClose,
  failedQuestionNumber,
  correctAnswer,
  failedAnswer,
  solutions,
}) => {
  if (!visible) return null;


  return (
    <div className="F-alert-modal">
      <div className="F-alert-container">
        <div className="F-alert-header">
          <h2 className="F-alert-title">{title}</h2>
          <p className="F-alert-message">{message}</p>
        </div>

        <div className="failed-questions-container">
          <div className="failed-questions-scroll">
            {failedQuestionNumber &&
              correctAnswer &&
              failedAnswer &&
              solutions &&
              failedQuestionNumber.map((questionNumber, index) => (
                correctAnswer[index] !== undefined && (
                  <div className="failed-question" key={index}>
                    <p className="failed-question-title">
                      Failed Question: {questionNumber}
                    </p>
                    <p className="failed-answer">
                      Your Answer: {failedAnswer[index]}
                    </p>
                    <p className="correct-answer">
                      Correct Answer: {correctAnswer[index]}
                    </p>
                    

                    {solutions[index] && (
                      <p className="solution">Solution: {solutions[index]}</p>
                    )}
                  </div>
                )
              ))}
          </div>
        </div>

        <div className="retry-button-container">
          <button onClick={onClose} className="retry-button">
            Retry
          </button>
        </div>
      </div>
    </div>
  );
};

export default TestFailedAlertPage;
