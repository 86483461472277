import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { API_URL } from '../config';
import { FaArrowLeft } from 'react-icons/fa';
import '../css/TheoryQuestionsPage.css';

const TheoryQuestionsPage = () => {
  const navigate = useNavigate();
  const { theoryNumberId } = useParams();

  const [showAnswers, setShowAnswers] = useState({});
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchAuthToken = () => {
    const tokenString = localStorage.getItem('authToken');
    return tokenString ? JSON.parse(tokenString).access : null;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      const token = fetchAuthToken();
      if (!token) {
        setError('Authentication error. Please log in again.');
        setIsLoading(false);
        return;
      }

      try {
        // Fetch theory questions
        const response = await fetch(
          `${API_URL}/theory-Questions/${theoryNumberId}/questions`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch theory questions.');
        }

        const data = await response.json();
        setQuestions(data.theory_questions || []);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [theoryNumberId]);

  const toggleAnswerVisibility = (index) => {
    setShowAnswers((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return (
      <div className="error-container">
        <div className="error">{error}</div>
        <button onClick={() => navigate(-1)} className="back-button">
          <FaArrowLeft className="icon" />
         
        </button>
      </div>
    );
  }

  if (!questions.length) {
    return <div className="no-data">COMING SOON!!!</div>;
  }

  return (
    <div className="questions-page">
      <header className="header">
        <button onClick={() => navigate(-1)} className="back-button">
          <FaArrowLeft className="icon" />
          Back
        </button>
        <h1>Theory Questions</h1>
      </header>

      <div className="questions-container">
        {questions.map((question, index) => (
          <div key={index} className="question-card">
            <h3>Question {index + 1}</h3>
            <div className="question-content">
              {question.quesImg && (
                <img
                  src={question.quesImg}
                  alt="Question"
                  className="question-image"
                />
              )}
              <p>{question.question}</p>
            </div>

            <button
              className="toggle-answer"
              onClick={() => toggleAnswerVisibility(index)}
            >
              {showAnswers[index] ? 'Hide Answer' : 'Show Answer'}
            </button>

            {showAnswers[index] && (
              <div className="answer-content">
                <h3>Answer</h3>
                {question.answerImg && (
                  <img
                    src={question.answerImg}
                    alt="Answer"
                    className="answer-image"
                  />
                )}
                <p>{question.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TheoryQuestionsPage;
