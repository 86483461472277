import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import '../css/AdminEditStudentPage.css'
import {API_URL} from '../config'
import { FaArrowLeft } from 'react-icons/fa';


export default function AdminEditStudentPage() {
  const { studentId } = useParams();
  const navigate = useNavigate();

  const [isStaff, setIsStaff] = useState(false);
  const [isSuperUser, setIsSuperUser] = useState(false);

  useEffect(() => {
    fetch(`${API_URL}/admin/student/${studentId}/`)
      .then((response) => response.json())
      .then((data) => {
        setIsStaff(data.is_staff);
        setIsSuperUser(data.is_superuser);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [studentId]);

  const updateStudent = () => {
    const updateData = { is_staff: isStaff, is_superuser: isSuperUser };

    fetch(`${API_URL}/admin/student/${studentId}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updateData),
    })
      .then((response) => response.json())
      .then(() => {
        
        navigate(-1); // Go back to the previous page
      })
      .catch((error) => {
        console.error("Error updating student:", error);
      });
  };

  return (
    <div className="admin-edit-container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1>Edit Student</h1>
      <div className="toggle-container">
        <div
          className={`checkbox ${isStaff ? "selected" : ""}`}
          onClick={() => setIsStaff(!isStaff)}
        />
        <label>Is Staff</label>
      </div>
      <div className="toggle-container">
        <div
          className={`checkbox ${isSuperUser ? "selected" : ""}`}
          onClick={() => setIsSuperUser(!isSuperUser)}
        />
        <label>Is SuperUser</label>
      </div>
      <button className="update-button" onClick={updateStudent}>
        Update Option
      </button>
    </div>
  );
}
