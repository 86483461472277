import React, { useState, useEffect, useCallback, memo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/AdminGoldPage.css';
import { FaArrowLeft } from 'react-icons/fa';
import { API_URL } from '../config';

const AdminGoldPage = () => {
  const { countryId } = useParams(); // Extracting countryId from route params
  const navigate = useNavigate();

  const [coins, setCoins] = useState([]);
  const [coinsValue, setCoinsValue] = useState('');
  const [currency, setCurrency] = useState('');
  const [amount, setAmount] = useState('');
  const [editingCoin, setEditingCoin] = useState(null);

  // Memoize fetchCoins to avoid unnecessary re-creation on every render
  const fetchCoins = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/admin/coins/${countryId}/`);
      setCoins(response.data);
    } catch (error) {
      console.error('Error fetching coins:', error);
    }
  }, [countryId]); // Dependency on countryId

  // Wrap handleDeleteCoin in useCallback to prevent re-creation on every render
  const handleDeleteCoin = useCallback(async (id) => {
    try {
      await axios.delete(`/admin/coin/${id}/`);
      fetchCoins(); // Refetch coins after deletion
    } catch (error) {
      console.error('Error deleting coin:', error);
    }
  }, [fetchCoins]); // Make sure fetchCoins is included as a dependency

  // Fetch coins whenever countryId changes
  useEffect(() => {
    fetchCoins();
  }, [countryId, fetchCoins]); // Add fetchCoins as a dependency

  const handleCreateCoin = async () => {
    try {
      await axios.post(
        `${API_URL}/admin/coins/${countryId}/`,
        { coins: coinsValue, currency, amount }
      );
      fetchCoins();
      resetForm();
    } catch (error) {
      console.error('Error creating coin:', error);
    }
  };

  const handleUpdateCoin = async () => {
    try {
      await axios.put(
        `${API_URL}/admin/coin/${editingCoin.id}/`,
        { coins: coinsValue, currency, amount }
      );
      fetchCoins();
      resetForm();
    } catch (error) {
      console.error('Error updating coin:', error);
    }
  };

  const resetForm = () => {
    setCoinsValue('');
    setCurrency('');
    setAmount('');
    setEditingCoin(null);
  };

  const renderItem = useCallback(
    (item) => (
      <CoinItem
        key={item.id}
        item={item}
        setCoinsValue={setCoinsValue}
        setCurrency={setCurrency}
        setAmount={setAmount}
        setEditingCoin={setEditingCoin}
        handleDeleteCoin={handleDeleteCoin}
      />
    ),
    [handleDeleteCoin] // Add handleDeleteCoin as a dependency
  );

  return (
    <div className="admin-container">
      <button onClick={() => navigate(-1)} className="back-button">
        <FaArrowLeft className="white-arrow" />Back
      </button>
      <input
        type="number"
        placeholder="Number of Coins"
        value={coinsValue}
        onChange={(e) => setCoinsValue(e.target.value)}
        className="admin-input"
      />
      <input
        type="text"
        placeholder="Currency"
        value={currency}
        onChange={(e) => setCurrency(e.target.value)}
        className="admin-input"
      />
      <input
        type="number"
        placeholder="Amount"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        className="admin-input"
      />

      {editingCoin ? (
        <button className="admin-button" onClick={handleUpdateCoin}>
          Update Coin
        </button>
      ) : (
        <button className="admin-button" onClick={handleCreateCoin}>
          Add Coin
        </button>
      )}

      <div className="coins-list">{coins.map(renderItem)}</div>
    </div>
  );
};

const CoinItem = memo(({ item, setCoinsValue, setCurrency, setAmount, setEditingCoin, handleDeleteCoin }) => (
  <div className="coin-item">
    <div className="coin-details">
      <p>{`Coins: ${item.coins}, Currency: ${item.currency}, Amount: ${item.amount}`}</p>
    </div>
    <div className="coin-actions">
      <button
        className="action-button edit"
        onClick={() => {
          setCoinsValue(item.coins);
          setCurrency(item.currency);
          setAmount(item.amount);
          setEditingCoin(item);
        }}
      >
        Edit
      </button>
      <button className="action-button delete" onClick={() => handleDeleteCoin(item.id)}>
        Delete
      </button>
    </div>
  </div>
));

export default AdminGoldPage;
