import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import authImage from '../assets/images/AuthImg.png';
import liteIcon from '../assets/icons/LiteIcon.png';
import { AuthContext } from '../AuthContext';
import { API_URL } from '../config';
import '../css/LoginPage.css';
import { FaArrowLeft } from 'react-icons/fa';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);

  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Login - LiteUpper';
    setUsername('');
    setPassword('');
  }, []);

  const handleRegisterNavigation = () => {
    navigate('/register');
  };

  const handleResetPassword = () => {
    navigate('/send-reset-password');
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/login-user/`, { username, password });
      const token = response.data.token;
      await login(token);

      const userData = response.data;
      const isFirstLogin = userData.is_afliate;
      const isSuperUser = userData.is_superuser;
      const isStaff = userData.is_staff;

      if (isSuperUser) {
        navigate('/dashboard');
      } else if (isStaff) {
        navigate('/admin-exam-country');
      } else if (isFirstLogin === undefined || isFirstLogin === null) {
        navigate('/first-update-profile');
      } else {
        navigate('/home');
      }
    } catch (error) {
      const message = error.response?.data?.detail || 'An error occurred. Please check your internet or try again later.';
      if (error.response?.status === 403 && message === 'Account is not active') {
        navigate('/verification');
      } else {
        setError(message);
      }
    }
    setLoading(false);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="login-container">
      <div className="image-container">
        <img src={authImage} alt="Authentication Background" className="auth-image" />
        <div className="overlay"></div>
      </div>
      <div className="form-container">
        <div className="login-card">
          <button onClick={handleBackClick} className="back-button">
            <FaArrowLeft className="white-arrow" /> Back
          </button>
          <img src={liteIcon} alt="LiteUpper Icon" className="logo" />

          <input type="text" style={{ display: 'none' }} name="username" autoComplete="username" />
          <input type="password" style={{ display: 'none' }} name="password" autoComplete="current-password" />

          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value.trim())}
            className="login-input"
            name="user_name"
            autoComplete="off"
          />
          <div className="password-container">
            <input
              type={passwordVisible ? 'text' : 'password'}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="login-input"
              name="user_password"
              autoComplete="new-password"
            />
            <button
              type="button"
              className="toggle-password"
              onClick={() => setPasswordVisible(!passwordVisible)}
            >
              {passwordVisible ? 'Hide' : 'Show'}
            </button>
          </div>
          <button onClick={handleLogin} disabled={loading} className="login-button">
            {loading ? 'Loading...' : 'Login'}
          </button>
          {error && <div className="error-message">{error}</div>}
          <button onClick={handleResetPassword} className="forgot-password">
            Forgot password?
          </button>
        </div>
        <button onClick={handleRegisterNavigation} className="register-button">
          Don't have an account? Register
        </button>
      </div>
    </div>
  );
};

export default LoginPage;
