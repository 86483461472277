import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/AdminStudentPage.css';
import { FaArrowLeft } from 'react-icons/fa';
import { API_URL } from '../config';

export default function AdminStudentPage() {
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchStudents = useCallback(() => {
    fetch(`${API_URL}/admin/students/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        const sortedData = data.sort((a, b) => a.username.localeCompare(b.username));
        setStudents(sortedData);
        setFilteredStudents(sortedData);
        setLoading(false);
      })
      .catch(() => {
        alert('Failed to fetch students.');
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchStudents();
  }, [fetchStudents]);

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query === '') {
      setFilteredStudents(students);
    } else {
      setFilteredStudents(
        students.filter((student) => student.username.toLowerCase().includes(query.toLowerCase()))
      );
    }
  };

  const editStudent = (studentId) => {
    if (studentId) {
      navigate(`/admin-edit-student/${studentId}`);
    } else {
      alert('Invalid student ID');
    }
  };

  if (loading) {
    return (
      <div className="admin-container">
        <div className="loading">
          <div className="spinner"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="admin-container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <input
        type="text"
        placeholder="Search by Username"
        value={searchQuery}
        onChange={(e) => handleSearch(e.target.value)}
        className="admin-text-input"
      />
      <div className="student-list">
        {filteredStudents.map((student) => (
          <div key={student.id} className="student-item">
            <div className="student-info">{student.username}</div>
            <button
              className="edit-button"
              onClick={() => editStudent(student.id)}
            >
              Edit
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
