import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../css/AdminEditSubjectPage.css'; // Import CSS for styling
import { API_URL } from '../config';
import { FaArrowLeft } from 'react-icons/fa';

const AdminEditSubjectPage = () => {
  const navigate = useNavigate();
  const { subjectId } = useParams(); // Get `subjectId` from route params
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Fetch categories
    fetch(`${API_URL}/admin/subjectcategory/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => setCategories(data))
      .catch(() => alert('Failed to fetch categories'));

    // Fetch subject details
    fetch(`${API_URL}/admin/subject/${subjectId}/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setName(data.name);
        setCategory(data.category); // Assuming category is the ID
      })
      .catch(() => alert('Failed to fetch subject details'));
  }, [subjectId]);

  const updateSubject = () => {
    if (!name.trim()) {
      alert('subject name are required');
      return;
    }

    fetch(`${API_URL}/admin/subject/${subjectId}/`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name,  category: category || null }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(() => {
        
        navigate(-1); // Go back to the previous page
      })
      .catch(() => alert('Failed to update subject'));
  };

  return (
    <div className="admin-edit-container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1>Edit Subject</h1>
      <div className="form-group">
        <label htmlFor="subject-name">Subject Name</label>
        <input
          id="subject-name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="form-input"
          placeholder="Enter subject name"
        />
      </div>
      <div className="form-group">
        <label htmlFor="category">Category</label>
        <select
          id="category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          className="form-input"
        >
          <option value="">Select category</option>
          {categories.map((cat) => (
            <option key={cat.id} value={cat.id}>
              {cat.name}
            </option>
          ))}
        </select>
      </div>
      <button className="submit-button" onClick={updateSubject}>
        Update Subject
      </button>
    </div>
  );
};

export default AdminEditSubjectPage;
