import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../config";
import '../css/AdminTheoryYearPage.css'
import { FaArrowLeft } from 'react-icons/fa';

export default function AdminTheoryYearPage() {
  const navigate = useNavigate();
  const { theorySubjectId } = useParams();

  const [theoryYears, setTheoryYears] = useState([]);
  const [year, setYear] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTheoryYears, setFilteredTheoryYears] = useState([]);

  const fetchTheoryYears = useCallback(() => {
    fetch(`${API_URL}/admin/theoryYears/${theorySubjectId}/`)
      .then((response) => response.json())
      .then((data) => {
        setTheoryYears(data);
        setFilteredTheoryYears(data);
      })
      .catch(() => alert("Failed to fetch years."));
  }, [theorySubjectId]);

  useEffect(() => {
    fetchTheoryYears();
  }, [fetchTheoryYears]);

  const addTheoryYear = () => {
    if (!year) {
      alert("Year is required.");
      return;
    }

    setLoading(true);

    fetch(`${API_URL}/admin/theoryYears/${theorySubjectId}/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ year }),
    })
      .then((response) => response.json())
      .then((newTheoryYear) => {
        setTheoryYears([newTheoryYear, ...theoryYears]);
        setFilteredTheoryYears([newTheoryYear, ...theoryYears]);
        setYear("");
      })
      .catch(() => alert("Failed to add year."))
      .finally(() => setLoading(false));
  };

  const editYear = useCallback(
    (theoryYearId) => {
      navigate(`/admin-edit-theory-year/${theoryYearId}`);
    },
    [navigate]
  );

  const deleteYear = useCallback(
    (theoryYearId) => {
      if (window.confirm("Are you sure you want to delete this year?")) {
        fetch(`${API_URL}/admin/theoryYear/${theoryYearId}/`, {
          method: "DELETE",
        })
          .then(() => {
            const updatedYears = theoryYears.filter(
              (theoryYear) => theoryYear.id !== theoryYearId
            );
            setTheoryYears(updatedYears);
            setFilteredTheoryYears(updatedYears);
          })
          .catch(() => alert("Failed to delete year."));
      }
    },
    [theoryYears]
  );

  const navigateToNumbers = useCallback(
    (theoryYearId) => {
      navigate(`/admin-theory-numbers/${theoryYearId}`);
    },
    [navigate]
  );

  const handleSearch = useCallback(
    (query) => {
      setSearchQuery(query);
      if (query === "") {
        setFilteredTheoryYears(theoryYears);
      } else {
        setFilteredTheoryYears(
          theoryYears.filter(
            (theoryYear) =>
              theoryYear.year &&
              theoryYear.year.toString().includes(query)
          )
        );
      }
    },
    [theoryYears]
  );

  return (
    <div className="container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <input
        placeholder="Theory Year"
        value={year}
        onChange={(e) => setYear(e.target.value)}
        className="year-text-input"
        type="number"
      />
      <button className="button" onClick={addTheoryYear} disabled={loading}>
        {loading ? "Adding..." : "Add Year"}
      </button>
      <input
        placeholder="Search"
        value={searchQuery}
        onChange={(e) => handleSearch(e.target.value)}
        className="year-text-input"
        type="text"
      />
      <ul className="year-list">
        {filteredTheoryYears.map((item) => (
          <li key={item.id} className="year-item">
            <div>
              <button
                onClick={() => navigateToNumbers(item.id)}
                className="navigate-button"
              >
                Year: {item.year}
              </button>
              <button
                onClick={() => editYear(item.id)}
                className="button"
              >
                Edit
              </button>
              <button
                onClick={() => deleteYear(item.id)}
                className="delete-button"
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
