import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { API_URL } from '../config';
import '../css/SubjectsPage.css';
import { FaArrowLeft } from 'react-icons/fa';
import Ads1Component from '../ads/Ads1';

const SubjectsPage = () => {
  const navigate = useNavigate();
  const { examId } = useParams();

  const [combinedList, setCombinedList] = useState([]);
  const [subjectsData, setSubjectsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchTokenAndCoinNum = async () => {
    try {
      const tokenString = localStorage.getItem('authToken'); // Use localStorage
      if (tokenString) {
        const token = JSON.parse(tokenString);

        const response = await fetch(`${API_URL}/student/profile/`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token.access}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          console.error('Error fetching coin_num:', response.statusText);
        }
      }
    } catch (error) {
      console.error('Error retrieving token:', error);
    }
  };

  const fetchSubjectsData = useCallback(async () => {
    try {
      const tokenString = localStorage.getItem('authToken');
      if (tokenString) {
        const token = JSON.parse(tokenString);

        const response = await fetch(`${API_URL}/exams/${examId}/subjects`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token.access}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setSubjectsData(data);
        } else {
          console.error('Error fetching subjects data:', response.statusText);
          setError('Failed to load subjects');
        }
      }
    } catch (error) {
      console.error('Error fetching subjects data:', error);
      setError('Failed to load subjects');
    } finally {
      setIsLoading(false);
    }
  }, [examId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchTokenAndCoinNum();
    fetchSubjectsData();
  }, [examId, fetchSubjectsData]);

  useEffect(() => {
    if (subjectsData && subjectsData.length > 0) {
      const sortedCategories = subjectsData.slice().sort((a, b) =>
        a.category_name.localeCompare(b.category_name)
      );

      const combinedList = sortedCategories.reduce((acc, category) => {
        if (category.category_name) {
          acc.push({ type: 'category', name: category.category_name });
        }

        const sortedSubjects = (Array.isArray(category.subjects) ? category.subjects : []).slice().sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        sortedSubjects.forEach((subject) => {
          acc.push({ type: 'subject', ...subject });
        });

        return acc;
      }, []);

      setCombinedList(combinedList);
    }
  }, [subjectsData]);

  const handleSubjectClick = (subjectId) => {
    navigate(`/tests/${examId}/${subjectId}`);
  };

  const hasCategories = combinedList.some((item) => item.type === 'category');

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return (
      <div className="error">
        {error}
        <button onClick={() => navigate(-1)} className="back-button">
          <FaArrowLeft className="white-arrow" />
         
        </button>
      </div>
    );
  }

  if (!combinedList || combinedList.length === 0) {
    return (
      <div className="no-data">
        COMING SOON!!!
        <button onClick={() => navigate(-1)} className="back-button">
          <FaArrowLeft className="white-arrow" />
          Back
        </button>
      </div>
    );
  }

  return (
    <div className="subjects-page">
      <button onClick={() => navigate(-1)} className="back-button">
        <FaArrowLeft className="white-arrow" />
        Back
      </button>
      <div className="suContainer">
        <Ads1Component />
        {!hasCategories && (
          <button
            className="subject-theory"
            onClick={() => navigate(`/subjects-theory/${examId}`)}
          >
            Go to theory
          </button>
        )}
        <main className="subjects-container">
          {combinedList.map((item, index) =>
            item.type === 'category' ? (
              <h2 key={index} className="category-name">
                {item.name}:
              </h2>
            ) : (
              <div
                key={index}
                className="subject-item"
                onClick={() => handleSubjectClick(item.id)}
              >
                {item.name}
              </div>
            )
          )}
        </main>
      </div>
    </div>
  );
};

export default SubjectsPage;
