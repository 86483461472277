import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { API_URL } from "../config";
import '../css/AdminTheorySubjectPage.css'
import { FaArrowLeft } from 'react-icons/fa';

export default function AdminTheorySubjectpage() {
  const { examId } = useParams();
  const navigate = useNavigate();

  const [subjects, setSubjects] = useState([]);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchSubjects = useCallback(() => {
    fetch(`${API_URL}/admin/theorySubjects/${examId}/`)
      .then((response) => response.json())
      .then((data) => setSubjects(data))
      .catch(() => alert("Error: Failed to load subjects."));
  }, [examId]);

  useEffect(() => {
    fetchSubjects();
  }, [fetchSubjects]);

  const addSubject = () => {
    if (!name) {
      alert("Validation Error: Subject name is required.");
      return;
    }

    setLoading(true);

    fetch(`${API_URL}/admin/theorySubjects/${examId}/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name }),
    })
      .then((response) => response.json())
      .then((newSubject) => {
        setSubjects([newSubject, ...subjects]);
        setName("");
      })
      .catch(() => alert("Error: Failed to add subject."))
      .finally(() => setLoading(false));
  };

  const editSubject = (theorySubjectId) => {
    navigate(`/admin-edit-theory-subject/${theorySubjectId}`); // Navigate to edit subject page
  };

  const deleteSubject = (theorySubjectId) => {
    if (window.confirm("Are you sure you want to delete this subject?")) {
      fetch(`${API_URL}/admin/theorySubject/${theorySubjectId}/`, {
        method: "DELETE",
      })
        .then(() =>
          setSubjects(subjects.filter((subject) => subject.id !== theorySubjectId))
        )
        .catch(() => alert("Error: Failed to delete subject."));
    }
  };

  const navigateToYear = (theorySubjectId) => {
    navigate(`/admin-theory-year/${theorySubjectId}`); // Navigate to the year page
  };

  return (
    <div className="container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1>Subject Theory</h1>
      <input
        type="text"
        placeholder="Subject theory Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="textInput"
      />
      <button onClick={addSubject} disabled={loading} className="button">
        {loading ? "Adding..." : "Add Subject"}
      </button>
      <div className="subjectList">
        {subjects.map((item) => (
          <div key={item.id} className="subjectItem">
            <div className="subjectName" onClick={() => navigateToYear(item.id)}>
              {item.name}
            </div>
            <button onClick={() => editSubject(item.id)} className="button">
              Edit
            </button>
            <button
              onClick={() => deleteSubject(item.id)}
              className="button delete"
            >
              Delete
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
