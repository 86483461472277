import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../css/AdminEditExamQuestionPage.css';
import { API_URL } from '../config';
import { FaArrowLeft } from 'react-icons/fa';

export default function AdminEditExamQuestionPage() {
  const { questionId } = useParams();
  const navigate = useNavigate();

  const [number, setNumber] = useState('');
  const [text, setText] = useState('');
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    fetch(`${API_URL}/admin/exam/question/${questionId}/`)
      .then((response) => response.json())
      .then((data) => {
        setNumber(data.number.toString());
        setText(data.text);
        setImageUrl(data.Eq_img);
      });
  }, [questionId]);

  const updateQuestion = () => {
    fetch(`${API_URL}/admin/exam/question/${questionId}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ number, text }),
    }).then(() => navigate(-1)); // Navigate back to the previous page
  };

  return (
    <div className="edit-question-container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1>Edit Exam Question</h1>
      <input
        type="text"
        placeholder="Question Number"
        value={number}
        onChange={(e) => setNumber(e.target.value)}
        className="text-input"
      />
      <textarea
        placeholder="Question Text"
        value={text}
        onChange={(e) => setText(e.target.value)}
        className="text-input"
        rows="4"
      />
      {imageUrl && (
        <img
          src={imageUrl}
          alt="Question Illustration"
          className="image-preview"
          onError={(e) => {
            console.error('Error loading image:', e);
            e.target.style.display = 'none';
          }}
        />
      )}
      <button className="button" onClick={updateQuestion}>
        Update Question
      </button>
    </div>
  );
}
