import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../config";
import '../css/AdminEditTheoryYearPage.css'
import { FaArrowLeft } from 'react-icons/fa';

export default function AdminEditTheoryYearPage() {
  const { theoryYearId } = useParams();
  const navigate = useNavigate();
  const [year, setYear] = useState("");
  const [loading, setLoading] = useState(false);

  // Fetch the current year when the component loads
  useEffect(() => {
    fetch(`${API_URL}/admin/theoryYear/${theoryYearId}/`)
      .then((response) => response.json())
      .then((data) => setYear(data.year.toString()))
      .catch(() => alert("Error: Failed to fetch year."));
  }, [theoryYearId]);

  // Function to update the year
  const updateYear = () => {
    if (!year) {
      alert("Year is required.");
      return;
    }

    setLoading(true);

    fetch(`${API_URL}/admin/theoryYear/${theoryYearId}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ year }),
    })
      .then((response) => {
        if (!response.ok) throw new Error("Failed to update year.");
        return response.json();
      })
      .then(() => {
        
        navigate(-1); // Go back to the previous page
      })
      .catch(() => alert("Error: Failed to update year."))
      .finally(() => setLoading(false));
  };

  return (
    <div className="container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1>Edit Year</h1>
      <input
        type="number"
        placeholder="Year"
        value={year}
        onChange={(e) => setYear(e.target.value)}
        className="year-text-input"
      />
      <button className="button" onClick={updateYear} disabled={loading}>
        {loading ? "Updating..." : "Update Year"}
      </button>
    </div>
  );
}
