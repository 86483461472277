//locall
export const API_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://api.liteupper.com/api'
    : 'http://0.0.0.0:8000/api';

// liteupperdev
//export const API_URL = 'http://54.166.94.90/api';

// liteupper
//export const API_URL = 'http://54.165.143.30/api';


// liteupper
//export const API_URL = 'https://api.liteupper.com/api';