import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/AdminNewsCategoryListPage.css';
import { API_URL } from '../config';
import { FaArrowLeft } from 'react-icons/fa';

const AdminNewsCategoryListPage = () => {
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState('');
  const navigate = useNavigate();

  const fetchCategories = useCallback(() => {
    axios
      .get(`${API_URL}/admin/newscategory/`)
      .then((response) => setCategories(response.data))
      .catch((error) => console.error('Error fetching categories:', error));
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]); // Add fetchCategories to the dependency array

  const createCategory = () => {
    axios
      .post(`${API_URL}/admin/newscategory/`, { name })
      .then((response) => {
        setCategories([...categories, response.data]);
        setName('');
      })
      .catch((error) => console.error('Error creating category:', error));
  };

  const deleteCategory = (id) => {
    if (window.confirm('Are you sure you want to delete this category?')) {
      axios
        .delete(`${API_URL}/admin/newscategory/${id}/`)
        .then(() => {
          setCategories(categories.filter((category) => category.id !== id));
        })
        .catch((error) => console.error('Error deleting category:', error));
    }
  };

  const editCategory = (categoryId) => {
    navigate(`/admin-edit-news-category/${categoryId}`);
  };

  return (
    <div className="admin-container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1>News Categories</h1>
      <ul className="category-list">
        {categories.map((category) => (
          <li key={category.id} className="category-item">
            <span className="category-name">{category.name}</span>
            <button
              className="btn edit-btn"
              onClick={() => editCategory(category.id)}
            >
              Edit
            </button>
            <button className="btn delete-btn" onClick={() => deleteCategory(category.id)}>
              Delete
            </button>
          </li>
        ))}
      </ul>

      <div className="create-category">
        <input
          className="input"
          type="text"
          placeholder="Enter category name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <button className="btn create-btn" onClick={createCategory}>
          Create Category
        </button>
      </div>
    </div>
  );
};

export default AdminNewsCategoryListPage;
