import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/VerificationPage.css';

const VerificationPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleVerify = () => {
    setLoading(true);
    // Simulating a delay or API call
    setTimeout(() => {
      setLoading(false);
      alert('Please proceed to login after verifying your email.');
      navigate('/login');
    }, 1500); // Example delay of 1.5 seconds
  };

  return (
    <div className="verification-container">
      <p className="verification-text">
        A verification link has been sent to your email address.
      </p>
      <p className="verification-text">
        Please check your email and click the link to verify your account.
      </p>
      <button className="verification-button" onClick={handleVerify} disabled={loading}>
        OK
      </button>
      {loading && <div className="loading-indicator">Loading...</div>}
    </div>
  );
};

export default VerificationPage;
