import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/Navbar.css';

import logoImage from '../assets/mainlogo1.png'; // Adjust the path to your image

const AdminNavbar = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false); // State to toggle the menu visibility
  const navRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setMenuOpen(false); // Close menu when clicking outside
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const handleMenuToggle = (event) => {
    event.stopPropagation();
    setMenuOpen(prevState => !prevState);
  };

  const handleLogout = () => {
    const confirmLogout = window.confirm("Are you sure you want to logout?");
    if (confirmLogout) {
      localStorage.removeItem('authToken');
      navigate('/login');
    }
  };

  return (
    <nav className="navbar">
      <div className="logo">
        <Link to="/home">
          <img src={logoImage} alt="Logo" className="logo-image" />
        </Link>
        LiteUpper
      </div>

      <div
        className={"hamburger-icon"}
        onClick={handleMenuToggle}
        aria-expanded={menuOpen}
      >
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </div>

      <ul ref={navRef} className={`nav-links ${menuOpen ? 'open' : ''}`}>
        <li><Link to="/admin-news-country">News</Link></li>
        <li><Link to="/admin-exam-country">Exams</Link></li>
        <button onClick={handleLogout} className="logout-button">
            Logout
          </button>
        
      </ul>
    </nav>
  );
};

export default AdminNavbar;


