import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userToken, setUserToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const login = (token) => {
    setUserToken(token);
    try {
      const tokenString = JSON.stringify(token); // Serialize the token
      localStorage.setItem("authToken", tokenString);
      
    } catch (e) {
      console.error("Failed to save auth token", e);
    }
  };

  const logout = () => {
    setUserToken(null);
    try {
      localStorage.removeItem('authToken');
    } catch (e) {
      console.error('Failed to remove auth token', e);
    }
  };

  useEffect(() => {
    const loadToken = () => {
      try {
        const token = localStorage.getItem('authToken');
        if (token) {
          setUserToken(token);
        }
      } catch (e) {
        console.error('Failed to load auth token', e);
      } finally {
        setIsLoading(false);
      }
    };

    loadToken();
  }, []);

  return (
    <AuthContext.Provider value={{ userToken, login, logout, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};
