import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../css/AdminEditExamPage.css"; // Import custom styles
import { API_URL } from "../config";
import { FaArrowLeft } from "react-icons/fa";

export default function AdminEditExamPage() {
  const navigate = useNavigate();
  const { examId } = useParams();
  const [newname, setNewName] = useState("");
  const [imageUrl, setImageUrl] = useState(null); // For displaying the existing image
  const [ispostUTME, setIspostUTME] = useState(false);

  // Fetch exam details
  useEffect(() => {
    console.log("Fetching exam data...");
    fetch(`${API_URL}/admin/exam/${examId}/`)
      .then((response) => {
        console.log("Received response:", response);
        return response.json();
      })
      .then((data) => {
        console.log("Exam data:", data);
        setNewName(data.name);
        setImageUrl(data.exam_img); // Set the existing image URL
        setIspostUTME(data.is_postUTME);
      })
      .catch((error) => console.error("Error fetching exam data:", error));
  }, [examId]);

  // Update exam
  const updateExam = () => {
    console.log("Preparing to update exam...", newname, ispostUTME);
    const examData = {
      name: newname,
      ispostUTME: ispostUTME,
    };
  
    console.log("Data prepared:", examData);
  
    fetch(`${API_URL}/admin/exam/${examId}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(examData), // Send as JSON
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Update successful, data:", data);
        navigate(-1); // Navigate back
      })
      .catch((error) => console.error("Error updating exam:", error));
  };
  

  return (
    <div className="admin-edit-exam">
      <button
        onClick={() => {
          console.log("Navigating back...");
          navigate(-1);
        }}
        className="back-button"
      >
        <FaArrowLeft className="white-arrow" />
        Back
      </button>
      <h1>Edit Exam</h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          console.log("Form submitted");
          updateExam();
        }}
        className="edit-form"
      >
        {/* Name Input */}
        <div className="form-group">
          <label htmlFor="name">Exam Name</label>
        <input
          type="text"
            id="name"
            value={newname}
            onChange={(e) => {
              console.log("Name changed:", e.target.value);
              setNewName(e.target.value);
            }}
            placeholder="Enter exam name"
            required
        />
      </div>

        {/* Is PostUTME Toggle */}
        <div className="form-group">
          <label>Is PostUTME</label>
          <button
            type="button"
            className={`toggle-button ${ispostUTME ? "active" : ""}`}
            onClick={() => {
              console.log("Toggling IsPostUTME:", !ispostUTME);
              setIspostUTME((prev) => !prev);
            }}
          >
            {ispostUTME ? "Yes" : "No"}
          </button>
      </div>

        {/* Existing Image Display */}
        {imageUrl && (
          <div className="form-group">
            <label>Existing Exam Image</label>
            <div className="image-preview">
              <img src={imageUrl} alt="Exam Preview" />
      </div>
          </div>
        )}

        {/* Submit Button */}
        <button type="submit" className="Exsubmit-button">
        Update Exam
      </button>
      </form>
    </div>
  );
}
