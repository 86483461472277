import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../css/Navbar.css';
import logoImage from '../assets/mainlogo1.png'; // Adjust the path to your image

const MainNavbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleMenuToggle = (event) => {
    event.stopPropagation(); // Prevent immediate menu closure
    setMenuOpen(prevState => !prevState);
  };

  return (
    <nav className="navbar">
      <div className="logo">
        <Link to="/">
          <img src={logoImage} alt="Logo" className="logo-image" />
        </Link>
        LiteUpper
      </div>

      <div
        className="hamburger-icon"
        onClick={handleMenuToggle}
        aria-expanded={menuOpen}
      >
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </div>

      <ul ref={navRef} className={`nav-links ${menuOpen ? 'open' : ''}`}>
        <li><Link to="/login">Login</Link></li>
        <li><a href="https://sites.google.com/view/liteupper-privacy-policy/home">Privacy Policy</a></li>
      </ul>
    </nav>
  );
};

export default MainNavbar;
