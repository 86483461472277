import React from 'react';
import '../../css/ExamSuccessAlertPage.css';



const ExamSuccessAlertPage = ({ visible, title, totalQuestion, correctCount, message, onClose }) => {
  if (!visible) return null;

  return (
    <div className="modal-overlay">
      
      <div className="modal-container">
       
          <h2 className="modal-title">{title}</h2>
          <p className="modal-message">
            {message}: {correctCount}
           
          </p>
          <p className="modal-message">
           
            Total question: {totalQuestion}
          </p>

        
        <button className="button button-next" onClick={onClose}>
          Next
        </button>
      </div>
    </div>
  );
};

export default ExamSuccessAlertPage;
