import React from 'react';
import Ads1Component from '../../ads/Ads1';
import SucImg from '../../assets/images/pass.jpg'
import '../../css/TestSuccessAlertPage.css';

const TestSuccessAlertPage = ({ visible, title, message, onClose }) => {
  if (!visible) return null;

  return (
    <div className="alert-modal">
      <div className="alert-background">
        <Ads1Component />
        <div className="alert-container">
          <div className="alert-card-container">
            <div className="alert-card">
              <img
                src={SucImg}
                alt="Congratulations"
                className="alert-image"
              />
              <h2 className="alert-title">{title}</h2>
              <p className="alert-message">{message}</p>
            </div>
            <button className="alert-next-button" onClick={onClose}>
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestSuccessAlertPage;
