import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/AdminNewsCategoryEditPage.css';
import { API_URL } from '../config';
import { FaArrowLeft } from 'react-icons/fa';

const AdminEditNewsCategoryPage = () => {
  const { categoryId } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch category details when the component loads
    axios
      .get(`${API_URL}/admin/newscategory/${categoryId}/`)
      .then((response) => {
        setName(response.data.name);
      })
      .catch((error) => {
        console.error('Error fetching category:', error);
        alert('Failed to fetch category details.');
      });
  }, [categoryId]);

  const updateCategory = () => {
    setLoading(true);
    axios
      .put(`${API_URL}/admin/newscategory/${categoryId}/`, { name })
      .then(() => {
        
        navigate(-1); // Go back to the previous page
      })
      .catch((error) => {
        console.error('Error updating category:', error);
        alert('Failed to update category.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="edit-category-container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1>Edit Category</h1>
      <input
        className="input-field"
        type="text"
        placeholder="Enter category name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <button
        className="update-button"
        onClick={updateCategory}
        disabled={loading}
      >
        {loading ? 'Updating...' : 'Update Category'}
      </button>
    </div>
  );
};

export default AdminEditNewsCategoryPage;
