import React from 'react';
import '../css/Footer.css';

const HomeFooter = () => {
  return (
    <footer className="footer">
      <p>LiteUpper © 2024 - Your trusted exam prep partner.</p>
    </footer>
  );
};

export default HomeFooter;
