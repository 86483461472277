import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../css/AdminPromoInstancesPage.css";
import { FaArrowLeft } from 'react-icons/fa';
import { API_URL } from "../config";

const AdminPromoInstancesPage = () => {
  const navigate = useNavigate();
  const [dates, setDates] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/admin/promo-instances/`)
      .then((response) => {
        // Sort dates by `date_created__date` in descending order
        const sortedDates = response.data.sort(
          (a, b) => new Date(b.date_created__date) - new Date(a.date_created__date)
        );
        setDates(sortedDates);
      })
      .catch((error) => console.error("Error fetching promo instances:", error));
  }, []);

  const handleDateClick = (date) => {
    navigate(`/admin-promo-instance-detail/${date}`);
  };

  return (
    <div className="promo-container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1 className="header-title">Promo Dates</h1>
      <div className="promo-list">
        {dates.map((item) => (
          <div
            key={item.date_created__date}
            className="promo-item"
            onClick={() => handleDateClick(item.date_created__date)}
          >
            <p className="date-text">{item.date_created__date}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminPromoInstancesPage;
