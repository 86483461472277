import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../css/AdminEditTheoryQuestionPage.css'; // Import the CSS file for styling
import { API_URL } from '../config';
import { FaArrowLeft } from 'react-icons/fa';

export default function AdminEditTheoryQuestionPage() {
  const { theoryQuestionId } = useParams();
  const navigate = useNavigate();

  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [qImageUrl, setQImageUrl] = useState(null);
  const [sImageUrl, setSImageUrl] = useState(null);

  // Fetch existing question data when the component mounts
  useEffect(() => {
    fetch(`${API_URL}/admin/theoryQuestion/${theoryQuestionId}/`)
      .then(response => response.json())
      .then(data => {
        setQuestion(data.question);
        setAnswer(data.answer);
        setQImageUrl(data.quesImg);
        setSImageUrl(data.ansImg);
      })
      .catch(() => {
        alert("Failed to fetch question details.");
      });
  }, [theoryQuestionId]);
  console.log(qImageUrl, sImageUrl);

  // Update question API call
  const updateQuestion = () => {
    fetch(`${API_URL}/admin/theoryQuestion/${theoryQuestionId}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ question, answer }),
    })
      .then(response => response.json())
      .then(() => {
        navigate(-1); // Navigate back to the previous page after successful update
      })
      .catch(() => {
        alert("Failed to update the question.");
      });
  };

  return (
    <div className="edit-theory-question-container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1>Edit Theory Question</h1>
      <div className="input-group">
        <label htmlFor="question">Question:</label>
        <textarea
          id="question"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Edit Question Text"
          className="text-input"
        />
      </div>

      {qImageUrl && (
      <div className="image-container">
              <img src={qImageUrl} alt="Question" className="image-preview" />
      </div>
    )}

      <div className="input-group">
        <label htmlFor="answer">Answer:</label>
        <textarea
          id="answer"
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          placeholder="Edit Answer"
          className="text-input"
        />
      </div>

        {sImageUrl && (
          <div className="image-container">
                  <img src={sImageUrl} alt="Solution" className="image-preview" />
          </div>
        )}

      <button className="update-button" onClick={updateQuestion}>
        Update Question
      </button>
    </div>
  );
}
