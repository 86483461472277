import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import EmailVerificationPage from './pages/EmailVerificationPage';
import HomeNavbar from './components/HomeNavbar';
import MainNavbar from './components/MainNavbar';
import HomeFooter from './components/HomeFooter';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import VerificationPage from './pages/VerificationPage';
import SendPasswordResetEmailPage from './pages/SendPasswordResetEmailPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import HomePage from './pages/HomePage';
import FirstUpdateProfilePage from './pages/FirstUpdateProfilePage';
import SecUpdateProfilePage from './pages/SecUpdateProfilePage';
import InstitutionPage from './pages/InstitutionPage';
import SubjectsPage from './pages/SubjectsPage';
import NotFoundPage from './pages/NotFoundPage';
import TestLevelsPage from './pages/TestLevelsPage';
import QuestionsPage from './pages/QuestionsPage';
import ExamPage from './pages/ExamPage';
import SubjectTheoryPage from './pages/SubjectTheoryPage';
import TheoryYearsPage from './pages/TheoryYearPage';
import GoldPage from './pages/GoldPage';
import TheoryNumbersPage from './pages/TheoryNumberScreen';
import TheoryQuestionsPage from './pages/TheoryQuestionsPage';
import ProfilePage from './pages/ProfilePage';
import PromoCodePage from './pages/PromoCodePage';
import StudentsPage from './pages/StudentsPage';
import PaystackStudentsPaymentPage from './pages/PaystackStudentsPaymentPage';
import PaystackPaymentPage from './pages/PaystackPaymentScreen';
import NewsPage from './pages/NewsPage';
import DashboardPage from './pages/DashboardPage';
import AdminNavbar from './components/AdminNavbar';
import AdminExamCountryPage from './pages/AdminExamCountryPage';
import AdminExamPage from './pages/AdminExamPage';
import AdminEditExamPage from './pages/AdminEditExamPage';
import AdminSubjectPage from './pages/AdminSubjectPage';
import AdminSubjectCategoryPage from './pages/AdminSubjectCategoryPage';
import AdminSubjectCategoryEditPage from './pages/AdminSubjectCategoryEditPage';
import AdminEditSubjectPage from './pages/AdminEditSubjectPage';
import AdminTestPage from './pages/AdminTestPage';
import AdminEditTestPage from './pages/AdminEditTestPage';
import AdminTestQuestionPage from './pages/AdminTestQuestionPage';
import AdminEditTestQuestionPage from './pages/AdminEditTestQuestionPage';
import AdminTestOptionPage from './pages/AdminTestOptionPage';
import AdminEditTestOptionPage from './pages/AdminEditOptionPage';
import AdminExamTestPage from './pages/AdminExamTestPage';
import AdminEditExamTestPage from './pages/AdminEditExamTestPage';
import AdminExamQuestionPage from './pages/AdminExamQuestionPage';
import AdminEditExamQuestionPage from './pages/AdminEditExamQuestionPage';
import AdminExamOptionPage from './pages/AdminExamOptionPage';
import AdminEditExamOptionPage from './pages/AdminEditExamOptionPage';
import AdminTheorySubjectpage from './pages/AdminTheorySubjectPage';
import AdminEditTheorySubjectPage from './pages/AdminEditTheorySubjectPage';
import AdminTheoryYearPage from './pages/AdminTheoryYearPage';
import AdminEditTheoryYearPage from './pages/AdminEditTheoryYearPage';
import AdminTheoryNumberPage from './pages/AdminTheoryNumberPage';
import AdminEditTheoryNumberPage from './pages/AdminEditTheoryNumberPage';
import AdminTheoryQuestionPage from './pages/AdminTheoryQuestionPage';
import AdminEditTheoryQuestionPage from './pages/AdminEditTheoryQuestionPage';
import AdminNewsCountryPage from './pages/AdminNewsCountryPage';
import AdminNewsListPage from './pages/AdminNewsPage';
import AdminNewsEditPage from './pages/AdminEditNewsPage';
import AdminNewsCategoryListPage from './pages/AdminNewsCategoryPage';
import AdminEditNewsCategoryPage from './pages/AdminEditNewsCategoryPage';
import AdminStudentPage from './pages/AdminStudentPage';
import AdminEditStudentPage from './pages/AdminEditStudentPage';
import AdminPromoCodePage from './pages/AdminPromoCodePage';
import AdminPromoInstancesPage from './pages/AdminPromoInstancesPage';
import AdminPromoInstanceDetailPage from './pages/AdminPromoInstanceDetailPage';
import AdminLocationCountryPage from './pages/AdminLocationCountryPage';
import AdminLocationPage from './pages/AdminLocationPage';
import AdminLocationWithStudentCountPage from './pages/AdminLocationWithStudentCountPage';
import AdminCountryPage from './pages/AdminCountryPage';
import AdminGoldCountryPage from './pages/AdminGoldCountryPage';
import AdminGoldPage from './pages/AdminGoldPage';
import DeleteAccountPage from './pages/DeleteAccountPage';
import NotificationsPage from './pages/NotificationsPage';

const App = () => {
  const location = useLocation(); // Get current route

  // Paths where HomeNavbar should not be shown
  const hideNavbarPaths = [
    '/login',
    '/register',
    '/reset-password',
    '/send-reset-password',
    '/first-update-profile',
    '/secUpdateProfile',
  ];

  // Paths that should use MainNavbar
  const mainNavbarPaths = [
    '/institutions',
    '/subjects',
    '/tests',
    '/questions',
    '/Exam',
    '/subjects-theory',
    '/theory-years',
    '/golds',
    '/theory-numbers',
    '/theory-questions',
    '/profile',
    '/promo-code',
    '/students',
    '/news',
    '/home',
    '/delete-account',
    '/paystack-payment',
    '/PaystackStudentsPayment',
    '/notifications'
  ];

  // Check the current location
  const isMainNavbar = mainNavbarPaths.some((path) =>
    location.pathname.startsWith(path)
  );
  const isAdminNavbar = [
    '/dashboard',
    '/admin-news-country',
    '/admin-exam-country',
    '/admin-exam',
    '/admin-edit-exam',
    '/admin-subject',
    '/admin-subject-category',
    '/admin-edit-category',
    '/admin-edit-subject',
    '/admin-test',
    '/admin-edit-test',
    '/admin-test-question',
    '/admin-edit-test-question',
    '/admin-test-options',
    '/admin-edit-test-option',
    '/admin-exam-test',
    '/admin-edit-exam-test',
    '/admin-exam-questions',
    '/admin-edit-exam-question',
    '/admin-exam-options',
    '/admin-edit-exam-option',
    '/admin-theory-subject',
    '/admin-edit-theory-subject',
    '/admin-theory-year',
    '/admin-edit-theory-year',
    '/admin-theory-numbers',
    '/admin-edit-theory-number',
    '/admin-theory-question',
    '/admin-edit-theory-question',
    '/admin-news-country',
    '/admin-news-list',
    '/admin-edit-news',
    '/admin-news-categories',
    '/admin-edit-news-category',
    '/admin-student',
    '/admin-edit-student',
    '/admin-promo-code',
    '/admin-promo-instance',
    '/admin-promo-instance-detail',
    '/admin-location-country',
    '/admin-location',
    '/admin-location-count',
    '/admin-country',
    '/admin-gold-country',
    '/admin-golds',
   
  ].some((path) => location.pathname.startsWith(path));

  return (
    <>
      {!hideNavbarPaths.includes(location.pathname) && (
        isAdminNavbar ? (
          <AdminNavbar /> // Show AdminNavbar for DashboardPage and HomePage
        ) : isMainNavbar ? (
          <MainNavbar /> // Show MainNavbar for other main pages
        ) : (
          <HomeNavbar /> // Show HomeNavbar for other pages
        )
      )}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/verification" element={<VerificationPage />} />
        <Route path="/verify" element={<EmailVerificationPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/send-reset-password" element={<SendPasswordResetEmailPage />} />
        <Route path="/first-update-profile" element={<FirstUpdateProfilePage />} />
        <Route path="/secUpdateProfile" element={<SecUpdateProfilePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/golds" element={<GoldPage />} />
        <Route path="/subjects/:examId" element={<SubjectsPage />} />
        <Route path="/tests/:examId/:subjectId" element={<TestLevelsPage />} />
        <Route path="/Exam/:examId/:subjectId/:testId" element={<ExamPage />} />
        <Route path="/questions/:examId/:subjectId/:testId" element={<QuestionsPage />} />
        <Route path="/subjects-theory/:examId" element={<SubjectTheoryPage />} />
        <Route path="/theory-years/:examId/:theorySubjectId" element={<TheoryYearsPage />} />
        <Route path="/theory-numbers/:theoryYearId" element={<TheoryNumbersPage />} />
        <Route path="/theory-questions/:theoryNumberId" element={<TheoryQuestionsPage />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/institutions" element={<InstitutionPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/promo-code" element={<PromoCodePage />} />
        <Route path="/students" element={<StudentsPage />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/paystack-payment" element={<PaystackPaymentPage />} />
        <Route path="/PaystackStudentsPayment" element={<PaystackStudentsPaymentPage />} />
        <Route path="/admin-exam-country" element={<AdminExamCountryPage />} />
        <Route path="/admin-exam/:countryId" element={<AdminExamPage />} />
        <Route path="/admin-edit-exam/:examId" element={<AdminEditExamPage />} />
        <Route path="/admin-subject/:examId" element={<AdminSubjectPage />} />
        <Route path="/admin-edit-subject/:subjectId" element={<AdminEditSubjectPage />} />
        <Route path="/admin-subject-category" element={<AdminSubjectCategoryPage />} />
        <Route path="/admin-edit-category/:categoryId" element={<AdminSubjectCategoryEditPage />} />
        <Route path="/admin-test/:subjectId" element={<AdminTestPage />} />
        <Route path="/admin-edit-test/:testId" element={<AdminEditTestPage />} />
        <Route path="/admin-test-question/:testId" element={<AdminTestQuestionPage />} />
        <Route path="/admin-edit-test-question/:questionId" element={<AdminEditTestQuestionPage />} />
        <Route path="/admin-test-options/:questionId" element={<AdminTestOptionPage />} />
        <Route path="/admin-edit-test-option/:optionId" element={<AdminEditTestOptionPage />} />
        <Route path="/admin-exam-test/:subjectId" element={<AdminExamTestPage />} />
        <Route path="/admin-edit-exam-test/:testId" element={<AdminEditExamTestPage />} />
        <Route path="/admin-exam-questions/:testId" element={<AdminExamQuestionPage />} />
        <Route path="/admin-edit-exam-question/:questionId" element={<AdminEditExamQuestionPage />} />
        <Route path="/admin-exam-options/:questionId" element={<AdminExamOptionPage />} />
        <Route path="/admin-edit-exam-option/:optionId" element={<AdminEditExamOptionPage />} />
        <Route path="/admin-theory-subject/:examId" element={<AdminTheorySubjectpage />} />
        <Route path="/admin-edit-theory-subject/:theorySubjectId" element={<AdminEditTheorySubjectPage />} />
        <Route path="/admin-theory-year/:theorySubjectId" element={<AdminTheoryYearPage />} />
        <Route path="/admin-edit-theory-year/:theoryYearId" element={<AdminEditTheoryYearPage />} />
        <Route path="/admin-theory-numbers/:theoryYearId" element={<AdminTheoryNumberPage/>} />
        <Route path="/admin-edit-theory-number/:theoryNumberId" element={<AdminEditTheoryNumberPage/>} />
        <Route path="/admin-theory-question/:theoryNumberId" element={<AdminTheoryQuestionPage/>} />
        <Route path="/admin-edit-theory-question/:theoryQuestionId" element={<AdminEditTheoryQuestionPage/>} />
        <Route path="/admin-news-country" element={<AdminNewsCountryPage/>} />
        <Route path="/admin-news-list/:countryId" element={<AdminNewsListPage/>} />
        <Route path="/admin-edit-news/:newsId" element={<AdminNewsEditPage/>} />
        <Route path="/admin-news-categories/" element={<AdminNewsCategoryListPage/>} />
        <Route path="/admin-edit-news-category/:categoryId" element={<AdminEditNewsCategoryPage/>} />
        <Route path="/admin-student" element={<AdminStudentPage/>} />
        <Route path="/admin-edit-student/:studentId" element={<AdminEditStudentPage/>} />
        <Route path="/admin-promo-code" element={<AdminPromoCodePage/>} />
        <Route path="/admin-promo-instance" element={<AdminPromoInstancesPage/>} />
        <Route path="/admin-promo-instance-detail/:date" element={<AdminPromoInstanceDetailPage/>} />
        <Route path="/admin-location-country" element={<AdminLocationCountryPage/>} />
        <Route path="/admin-location/:countryId" element={<AdminLocationPage/>} />
        <Route path="/admin-location-count" element={<AdminLocationWithStudentCountPage/>} />
        <Route path="/admin-country" element={<AdminCountryPage/>} />
        <Route path="/admin-gold-country" element={<AdminGoldCountryPage/>} />
        <Route path="/admin-golds/:countryId" element={<AdminGoldPage/>} />
        <Route path="/delete-account" element={<DeleteAccountPage/>} />
        <Route path="/notifications" element={<NotificationsPage/>} />
      </Routes>
      <HomeFooter />
    </>
  );
};


const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
