import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../css/AdminExamOptionPage.css";
import { API_URL } from "../config";
import { FaArrowLeft } from 'react-icons/fa';


export default function AdminExamOptionPage() {
  const { questionId } = useParams();
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState(null);
  const [options, setOptions] = useState([]);
  const [optionLetter, setOptionLetter] = useState("");
  const [text, setText] = useState("");
  const [isAnswer, setIsAnswer] = useState(false);
  const [imageUri, setImageUri] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchOptions = useCallback(() => {
    setLoading(true);
    fetch(`${API_URL}/admin/exam/options/${questionId}/`)
      .then((response) => response.json())
      .then((data) => {
        setOptions(data);
        setLoading(false);
      })
      .catch((error) => {
        alert("Failed to fetch options.");
        setLoading(false);
      });
  }, [questionId]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  const addOption = () => {
    if (!optionLetter || !text) {
      alert("Option Letter and Text are required.");
      return;
    }

    const formData = new FormData();
    formData.append("optionLetter", optionLetter);
    formData.append("text", text);
    formData.append("isAnswer", isAnswer);
    if (imageUri) {
      const file = imageUri.target.files[0];
      formData.append('Eoption_img', file);
    }

    fetch(`${API_URL}/admin/exam/options/${questionId}/`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((newOption) => {
        setOptions([newOption, ...options]);
        setOptionLetter("");
        setText("");
        setIsAnswer(false);
        setImageUri(null);
      })
      .catch(() => alert("Failed to add option."));
  };

  const editOption = (optionId) => {
    navigate(`/admin-edit-exam-option/${optionId}`);
  };

  const deleteOption = (optionId) => {
    if (window.confirm("Are you sure you want to delete this option?")) {
      fetch(`${API_URL}/admin/exam/option/${optionId}/`, {
        method: "DELETE",
      })
        .then(() => {
          setOptions(options.filter((option) => option.id !== optionId));
          alert("Option deleted successfully.");
        })
        .catch(() => alert("Failed to delete option."));
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageUri(e);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  return (
    <div className="admin-container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1>Exam Options</h1>

      <select
        value={optionLetter}
        onChange={(e) => setOptionLetter(e.target.value)}
        className="dropdown"
      >
        <option value="">Select Option Letter</option>
        <option value="A">Option A</option>
        <option value="B">Option B</option>
        <option value="C">Option C</option>
        <option value="D">Option D</option>
      </select>

      <textarea
        placeholder="Option Text"
        value={text}
        onChange={(e) => setText(e.target.value)}
        className="text-input"
      />

      <label className="checkbox-container">
        <input
          type="checkbox"
          checked={isAnswer}
          onChange={() => setIsAnswer((prev) => !prev)}
        />
        Is Answer
      </label>

      <input type="file" onChange={handleImageUpload} className="file-input" />
      {imagePreview && (
        <div className="image-preview-container">
          <img src={imagePreview} alt="Preview" className="image-preview" />
        </div>
      )}

      <button onClick={addOption} className="button">
        Add Option
      </button>

      <div className="options-list">
        {loading ? (
          <p>Loading options...</p>
        ) : (
          options.map((option) => (
            <div key={option.id} className="option-item">
              <p>
                Option: {option.option_letter}, Answer: {option.is_answer.toString()}
              </p>
              <button onClick={() => editOption(option.id)} className="button">
                Edit
              </button>
              <button
                onClick={() => deleteOption(option.id)}
                className="delete-button"
              >
                Delete
              </button>
            </div>
          ))
        )}
      </div>
    </div>
  );
}
