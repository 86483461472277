import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../css/AdminEditTestQuestionPage.css';
import { API_URL } from '../config';
import { FaArrowLeft } from 'react-icons/fa';



export default function AdminEditTestQuestionPage() {
  const { questionId } = useParams();
  const navigate = useNavigate();

  const [number, setNumber] = useState('');
  const [text, setText] = useState('');
  const [solution, setSolution] = useState('');
  const [imageUrl, setImageUrl] = useState(null);

  // Fetch question data
  useEffect(() => {
    fetch(`${API_URL}/admin/question/${questionId}/`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setNumber(data.number.toString());
        setText(data.text);
        setSolution(data.solution);
        setImageUrl(data.q_img);
      })
      .catch((error) => console.error('Error fetching question:', error));
  }, [questionId]);

  // Update question
  const updateQuestion = () => {
    fetch(`${API_URL}/admin/question/${questionId}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ number, text, solution }),
    })
      .then((response) => response.json())
      .then(() => navigate(-1))
      .catch((error) => console.error('Error updating question:', error));
  };

  return (
    <div className="edit-container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1>Edit Question</h1>
      <div className="form-container">
        <label>
          Question Number
          <input
            type="number"
            placeholder="Question Number"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            className="form-input"
          />
        </label>

        <label>
          Question Text
          <textarea
            placeholder="Question Text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            className="form-input"
          />
        </label>

        <label>
          Solution
          <textarea
            placeholder="Solution"
            value={solution}
            onChange={(e) => setSolution(e.target.value)}
            className="form-input"
          />
        </label>

        {imageUrl && (
          <div className="image-preview-container">
            <img
              src={imageUrl}
              alt="Preview"
              className="image-preview"
              onError={(e) => {
                e.target.style.display = 'none';
                console.error('Error loading image.');
              }}
            />
          </div>
        )}

        <button className="submit-button" onClick={updateQuestion}>
          Update Question
        </button>
      </div>
    </div>
  );
}
