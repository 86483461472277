import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../config';
import '../css/DeleteAccountPage.css';

const DeleteAccountPage = () => {
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAuthToken = async () => {
      try {
        const tokenString = localStorage.getItem('authToken'); // Using localStorage instead of AsyncStorage for web
        if (tokenString) {
          setAuthToken(JSON.parse(tokenString));
        }
      } catch (error) {
        console.error('Error retrieving token:', error);
      }
    };

    fetchAuthToken();
  }, []);

  const handleDeleteAccount = async () => {
    if (!password) {
      alert('Please enter your password.');
      return;
    }

    if (!authToken) {
      alert('Unable to retrieve authentication token. Please log in again.');
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch(`${API_URL}/delete-account/`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken.access}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password }),
      });

      const data = await response.json();

      if (response.ok) {
        alert(data.message);
        navigate('/login');
      } else {
        alert(data.error || 'Failed to delete account.');
      }
    } catch (error) {
      console.error('Error during account deletion:', error);
      alert('An error occurred while processing your request.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="delete-account-container">
      <h1 className="title">Delete Account</h1>
      <p className="description">
        Enter your password below to confirm account deletion. This action cannot be undone.
      </p>

      <input
        type="password"
        className="deleteInput"
        placeholder="Enter your password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

      <button
        className={`button ${isSubmitting ? 'button-disabled' : ''}`}
        onClick={handleDeleteAccount}
        disabled={isSubmitting}
      >
        {isSubmitting ? 'Processing...' : 'Delete Account'}
      </button>
    </div>
  );
};

export default DeleteAccountPage;
