import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "../css/PromoInstanceDetailPage.css";
import { FaArrowLeft } from 'react-icons/fa';
import { API_URL } from "../config";

const AdminPromoInstanceDetailPage = () => {
  const { date } = useParams(); // Get the date from the route parameters
  const navigate = useNavigate();
  const [details, setDetails] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/admin/promo-instances/${date}/`)
      .then((response) => {
        // Sort the details by profit in descending order
        const sortedDetails = response.data.sort((a, b) => b.profit - a.profit);
        setDetails(sortedDetails);
      })
      .catch((error) => console.error("Error fetching promo details:", error));
  }, [date]);

  return (
    <div className="promo-details-container">
      <div className="header">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
        <h1 className="header-title">Promo Details</h1>
      </div>
      <div className="details-list">
        {details.map((item, index) => (
          <div key={index} className="details-item">
            <p className="detail-text">
              <strong>Username:</strong> {item.username}
            </p>
            <p className="detail-text">
              <strong>Country:</strong> {item.country}
            </p>
            <p className="detail-text">
              <strong>State:</strong> {item.state}
            </p>
            <p className="detail-text">
              <strong>Profit:</strong> {item.profit}
            </p>
            <p className="detail-text">
              <strong>Bank Name:</strong> {item.bank_name}
            </p>
            <p className="detail-text">
              <strong>Account Name:</strong> {item.account_name}
            </p>
            <p className="detail-text">
              <strong>Account Number:</strong> {item.account_num}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminPromoInstanceDetailPage;
