import React, { useState, useEffect,  } from 'react';
import { useNavigate } from 'react-router-dom';
// For web you may want to use localStorage or another package for handling storage
import { API_URL } from '../config';

import '../css/SecUpdateProfilePage.css'; // Assuming you are using an external CSS file

const SecUpdateProfilePage = () => {
  const navigate = useNavigate();

  const [location, setLocation] = useState('');
  const [locations, setLocations] = useState([]);
  const [authToken, setAuthToken] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const retrieveAuthToken = async () => {
      try {
        const tokenString = localStorage.getItem('authToken'); // Replace AsyncStorage with localStorage for web
        if (tokenString) {
          const token = JSON.parse(tokenString);
          setAuthToken(token);
          fetchLocations(token);
        }
      } catch (error) {
        console.error('Error retrieving token:', error);
      }
    };

    retrieveAuthToken();
  }, []);

  const fetchLocations = async (token) => {
    try {
      const response = await fetch(`${API_URL}/student/update/`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token.access}`,
          'Content-Type': 'application/json'
        },
      });

      if (response.ok) {
        const data = await response.json();
        setLocations(data);
        setLoading(false);
      } else {
        console.error('Failed to fetch locations');
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  const handleUpdate = async () => {
    if (!location) {
      alert('Please select your state before updating.');
      return;
    }
    const updatedData = {
      location: location,
    };

    try {
      const response = await fetch(`${API_URL}/student/update/`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${authToken.access}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedData),
      });

      if (response.ok) {
        navigate('/home');
      } else {
        const data = await response.json();
        if (data && data.error) {
          alert(data.error);
        } else {
          alert('Failed to update profile. Please try again later.');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to update profile. Please try again later.');
    }
  };

  if (loading) {
    return (
      <div className="uPcontainer">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="uPcontainer">
      <h2 className="uPlabel">Location</h2>
      <div className="uPpicker">
        <select
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          className="picker"
        >
          <option value="">----Select your state----</option>
          {locations.map((loc) => (
            <option key={loc.id} value={loc.id}>
              {loc.state}
            </option>
          ))}
        </select>
      </div>

      <button onClick={handleUpdate} className="proceed-button">
        Proceed
      </button>
    </div>
  );
};

export default SecUpdateProfilePage;
