import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PaystackButton } from 'react-paystack';
import { API_URL } from '../config';


const PaystackStudentsPaymentPage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { studentDetails= [], amount, email, secretkey, publickey, totalStudents } = location.state || {};
    const [authToken, setAuthToken] = useState(null);
    const [currency, setCurrency] = useState('USD'); // Default currency

    useEffect(() => {
        const retrieveAuthToken = async () => {
            try {
                const tokenString = localStorage.getItem('authToken');
                if (tokenString) {
                    const token = JSON.parse(tokenString);
                    setAuthToken(token);
                    fetchUserProfile(token);
                }
            } catch (error) {
                console.error('Error retrieving token:', error);
            }
        };

        const fetchUserProfile = async (token) => {
            try {
                const response = await fetch(`${API_URL}/user/country/`, {
                    headers: {
                        'Authorization': `Bearer ${token.access}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    const userCurrency = data.country.currency;
                    setCurrency(userCurrency);
                } else {
                    console.error('Failed to fetch user profile');
                }
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };

        retrieveAuthToken();
    }, []);

    const updateUserCoins = async () => {
        if (!authToken) {
            alert('Authentication Error: Failed to retrieve authentication token.');
            return;
        }

        if (!studentDetails || studentDetails.length === 0) {
            alert('Error: No student details provided.');
            return;
        }

        try {
            const response = await fetch(`${API_URL}/update-student-coins/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken.access}`,
                },
                body: JSON.stringify({ studentDetails }),
            });

            if (!response.ok) {
                throw new Error('Failed to update user coins');
            }

            
            alert('Payment Successful: Your students have received their gold.');
            navigate(-1); // Go back to the previous screen
        } catch (error) {
            alert(`Update Error: An error occurred while updating coins: ${error.message}`);
            navigate(-1); // Go back to the previous screen
        }
    };

    const componentProps = {
        email,
        amount: amount * 100, // Convert amount to kobo for Paystack
        currency,
        publicKey: publickey,
        secretkey: secretkey,
        text: 'Make Payment',
        onSuccess: async (res) => {
          console.log('Payment successful:', res);
          const authToken = JSON.parse(localStorage.getItem('authToken'));
          if (authToken) {
            await updateUserCoins(authToken);
          }
        },
        onClose: () => {
          alert('Payment Cancelled');
          navigate('/students');
        },
      };

    

    return (
        <div style={{minHeight:'600px'}}>
          <div className="payment-summary-card">
            <h2>Payment Summary</h2>
            <p><strong>Total Amount: </strong>{amount} </p>
            <p><strong>Total Students:</strong> {totalStudents}</p>
            <div>
              <strong>Payment Details:</strong>
              <ul>
                {studentDetails.map((detail, idx) => (
                  <li key={idx}>{detail.email}: {detail.amount}</li>
                ))}
              </ul>
            </div>
            <div className="modal-actions">
            <button onClick={() => navigate(-1)} className="cancel-button">
              Close
            </button>
            <button className='pay-button'>
              <PaystackButton {...componentProps} />
            </button>
          </div>
        </div>
        </div>
    );
};

export default PaystackStudentsPaymentPage;
