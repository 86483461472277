import React, { useState, useEffect } from 'react';
import { Card } from '@mui/material'; // Material UI for Card component
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import moment from 'moment';
import axios from 'axios';
import Ads2Component from '../ads/Ads2';
import { API_URL } from '../config';

import '../css/NewsPage.css'; // Assuming we will use a separate CSS file for styling

const NewsItem = ({ news }) => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  return (
    <Card className="news-card">
      <button onClick={() => navigate(-1)} className="back-button">
    <FaArrowLeft className="white-arrow" />
    </button>
      <div className="news-img-container">
        <img src={news.news_img} alt={news.title} className="news-image" />
      </div>
      <div className="news-text-container">
        <h2 className="news-title">{news.title}</h2>
        <p className="news-date">{moment(news.date).fromNow()}</p>
        <p className="news-description">
          {expanded ? news.description : `${news.description.substring(0, 100)}...`}
        </p>
        <button onClick={() => setExpanded(!expanded)} className="news-see-more">
          {expanded ? 'Show less' : 'See more'}
        </button>
      </div>
    </Card>
  );
};

const NewsPage = () => {
  const [search, setSearch] = useState('');
  const [categories, setCategories] = useState([]);
  const [newsItems, setNewsItems] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  

  // Fetch the token and coin number
  const fetchTokenAndCoinNum = async () => {
    try {
      const tokenString = localStorage.getItem('authToken');
      if (tokenString) {
        const token = JSON.parse(tokenString);
      

        const response = await fetch(`${API_URL}/student/profile/`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token.access}`,
            'Content-Type': 'application/json'
          }
        });
        if (response.ok) {
          
        } else {
          console.error('Error fetching coin_num:', response.statusText);
        }
      }
    } catch (error) {
      console.error('Error retrieving token:', error);
    }
  };

  // Fetch categories and news data
  const fetchNewsData = async () => {
    try {
      const response = await axios.get(`${API_URL}/news/`);
      setCategories(response.data.categories);
      setNewsItems(response.data.all_news); // Set initial news items to all
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTokenAndCoinNum();
    fetchNewsData();
  }, []);

  // Update filtered news based on search and selected category
  const filteredNews = newsItems.filter(news =>
    news.title.toLowerCase().includes(search.toLowerCase())
  );

  const handleCategoryChange = (categoryId) => {
    if (categoryId === null) {
      fetchNewsData();
    } else {
      const selectedCategory = categories.find(category => category.id === categoryId);
      setNewsItems(selectedCategory ? selectedCategory.news_items : []);
    }
    setSelectedCategoryId(categoryId);
  };

  // Add ads and news items to the display
  const getNewsWithAds = () => {
    let newsWithAds = [];
    filteredNews.forEach((news, index) => {
      if ((index + 0) % 2 === 0) {
        newsWithAds.push(
          <Ads2Component key={`ad-${index}`} />
        );
      }
      newsWithAds.push(
        <NewsItem key={news.id} news={news} />
      );
    });
    return newsWithAds;
  };

  return (
    <div className="news-page">
      <h1>News</h1>
      <div className="news-search-bar">
        <input
          type="text"
          placeholder="Search news..."
          value={search}
          onChange={e => setSearch(e.target.value)}
          className="search-input"
        />
      </div>
      <div className="category-list">
        <button 
          onClick={() => handleCategoryChange(null)} 
          className={`main-category-item ${selectedCategoryId === null ? 'selected' : ''}`}
        >
          All
        </button>
        {categories.map(category => (
          <button 
            key={category.id} 
            onClick={() => handleCategoryChange(category.id)} 
            className={`main-category-item ${selectedCategoryId === category.id ? 'selected' : ''}`}
          >
            {category.name}
          </button>
        ))}
      </div>
      <div className="news-container">
        {getNewsWithAds()}
      </div>
    </div>
  );
};

export default NewsPage;
