import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../config';
import { FaArrowLeft } from 'react-icons/fa';
import '../css/StudentsPage.css'; // Import CSS for styling

const StudentsPage = () => {
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [email, setEmail] = useState('');
  const [publickey, setPublickey] = useState('');
  const [secretkey, setSecretkey] = useState('');
  const [fields, setFields] = useState([{ email: '', amount: '', isValid: true }]);
  const [coinOptions, setCoinOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchAuthToken = useCallback(async () => {
    try {
        const tokenString = localStorage.getItem('authToken');
        if (tokenString) {
            const token = JSON.parse(tokenString);
            fetchStudents(token);
            fetchCoinOptions(token);
            fetchEmail(token);
        }
    } catch (error) {
        console.error('Error retrieving token:', error);
    }
}, []);

useEffect(() => {
    fetchAuthToken();
}, [fetchAuthToken]);

  const fetchStudents = async (token) => {
    try {
      const response = await axios.get(`${API_URL}/institute-students/`, {
        headers: { Authorization: `Bearer ${token.access}` },
      });
      setStudents(response.data.students);
    } catch (err) {
      setError('Failed to load student data.');
    } finally {
      setLoading(false);
    }
  };

  const fetchCoinOptions = async (token) => {
    try {
      const response = await axios.get(`${API_URL}/coins/`, {
        headers: { Authorization: `Bearer ${token.access}` },
      });
      setCoinOptions(response.data);
    } catch (error) {
      console.error('Failed to load coin options:', error);
      setError('Failed to load coin options.');
    }
  };

  const fetchEmail = async (token) => {
    try {
      setLoading(true);
      setError(false);

      const emailResponse = await axios.get(`${API_URL}/student/profile/`, {
        headers: { Authorization: `Bearer ${token.access}` },
      });
      setEmail(emailResponse.data.user.email);
      setPublickey(emailResponse.data.publickey);
      setSecretkey(emailResponse.data.secretkey);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(true);
      setLoading(false);
      alert('Error: Failed to load data.');
    }
  };

  const handleAddField = () => {
    setFields([...fields, { email: '', amount: '', isValid: true }]);
  };

  const handleFieldChange = (index, type, value) => {
    const newFields = fields.map((field, idx) => {
      if (idx === index) {
        return { ...field, [type]: value };
      }
      return field;
    });

    if (type === 'email') {
      const isValid = students.some((student) => student.email === value);
      newFields[index].isValid = isValid;
    }

    setFields(newFields);
  };

  const handleRemoveField = (index) => {
    const newFields = fields.filter((_, idx) => idx !== index);
    setFields(newFields);
  };

  const handlePay = () => {
    let totalAmount = 0;
    let totalStudents = 0;
    const paymentDetails = [];

    
  
    fields.forEach((field) => {
      const student = students.find((s) => s.email === field.email);
      if (student && field.amount) {
        totalAmount += parseInt(field.amount, 10);
        totalStudents += 1;
        paymentDetails.push({ email: student.email, amount: field.amount });
      }
    });
    if (paymentDetails.length === 0) {
      alert('Please provide at least one valid email and amount before proceeding.');
      return;
    }
  
    // Navigate directly with the calculated payment details
    navigate('/PaystackStudentsPayment', {
      state: {
        amount: totalAmount,
        email,
        secretkey,
        publickey,
        studentDetails: paymentDetails,
        totalStudents,
      },
    });
  };
  

  const filteredStudents = students.filter((student) =>
    student.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="students-page">
      <button onClick={() => navigate(-1)} className="back-button">
        <FaArrowLeft className="white-arrow" />
      </button>
      <h1>Students Data</h1>

      <div className="fields">
        {fields.map((field, index) => (
          <div key={index} className="field-row">
            <input
              type="email"
              placeholder="Email"
              value={field.email}
              onChange={(e) => handleFieldChange(index, 'email', e.target.value)}
              className={`field-input ${field.isValid ? '' : 'invalid'}`}
            />
            <select
              value={field.amount}
              onChange={(e) => handleFieldChange(index, 'amount', e.target.value)}
              className="field-select"
            >
              <option value="">Amount</option>
              {coinOptions.map((amount, idx) => (
                <option key={idx} value={amount.amount}>
                  {amount.amount}
                </option>
              ))}
            </select>
            <button onClick={() => handleRemoveField(index)} className="remove-button">
              X
            </button>
            {!field.isValid && <span className="error-text">Invalid email</span>}
          </div>
        ))}
      </div>

      <div className="actions">
        <button onClick={handleAddField} className="add-button">Add More Students</button>
        <button onClick={handlePay} className="pay-button">Proceed</button>
      </div>

      <input
        type="text"
        placeholder="Search by student name"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />

      <div className="students-table-wrapper">
        <div className="students-table">
          <div className="table-header">
            <span>Name</span>
            <span>Email</span>
            <span>Gold</span>
            <span>E-level</span>
          </div>
          {filteredStudents.map((student, index) => (
            <div key={index} className="table-row">
              <span>{student.name}</span>
              <span>{student.email}</span>
              <span>{student.gold}</span>
              <span>{student.unlocked_tests_count}</span>
            </div>
          ))}
        </div>
      </div>
  </div>
  );
};

export default StudentsPage;
