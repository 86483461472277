import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../config';
import Lock from '../assets/icons/lock.png'
import '../css/TestLevelsPage.css';
import ExamBeginAlertPage from '../common/alert/ExamBeginAlertpage';
import { FaArrowLeft } from 'react-icons/fa';

const TestLevelsPage = () => {
  const navigate = useNavigate();
  const { examId, subjectId } = useParams();
  
  const [examImageUri, setExamImageUri] = useState(null);
  const [tests, setTests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clickedTest, setClickedTest] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [selectedExamTestId, setSelectedExamTestId] = useState(null);
  const flatListRef = useRef(null);
  

  const fetchTokenAndCoinNum = useCallback(async () => {
    try {
      const tokenString = localStorage.getItem('authToken');
      if (tokenString) {
        const token = JSON.parse(tokenString);
        const response = await axios.get(`${API_URL}/student/profile/`, {
          headers: {
            'Authorization': `Bearer ${token.access}`,
          },
        });

        if (response.status === 200) {
          
        }
        
      }
    } catch (error) {
      console.error('Error retrieving token:', error);
    }
  }, []);
  

  const scrollToTargetTest = (tests) => {
    if (flatListRef.current) {
      // Find the first locked test or the last unlocked test
      const firstLockedIndex = tests.findIndex(test => test.lock);
      const lastUnlockedIndex = tests.map((test, index) => (test.lock ? -1 : index)).filter(index => index >= 0).pop();
  
      const targetIndex = firstLockedIndex !== -1 ? firstLockedIndex : lastUnlockedIndex;
  
      if (targetIndex !== undefined && targetIndex !== -1) {
        flatListRef.current.scrollTo({
          top: targetIndex * 100,
          behavior: 'smooth',
        });
      }
    }
  };
  
  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const tokenString = localStorage.getItem('authToken');
      const token = tokenString ? JSON.parse(tokenString) : null;
  
      if (token) {
        const response = await axios.get(`${API_URL}/exams/${examId}/subjects/${subjectId}/tests`, {
          headers: {
            'Authorization': `Bearer ${token.access}`,
          },
        });
  
        const examTestResponse = await axios.get(`${API_URL}/main/exams/${examId}/subjects/${subjectId}/tests`, {
          headers: {
            'Authorization': `Bearer ${token.access}`,
          },
        });
  
        const data = response.data;
        const examTestData = examTestResponse.data.map(test => ({ ...test, isExamTest: true }));
  
        const testsWithExamTests = [];
        data.forEach((test, index) => {
          testsWithExamTests.push(test);
          if ((index + 1) % 10 === 0 && index + 1 < data.length) {
            testsWithExamTests.push(examTestData[Math.floor(index / 10)] || { isExamTest: true });
          }
        });
  
        setTests(testsWithExamTests);
  
        const examImgResponse = await axios.get(`${API_URL}/main/exams/img/${examId}/subjects/${subjectId}/tests`, {
          headers: {
            'Authorization': `Bearer ${token.access}`,
          },
        });
  
        setExamImageUri(examImgResponse.data.exam_image_url);
  
        // Scroll to the target test (first locked or last unlocked)
        scrollToTargetTest(testsWithExamTests);
  
      } else {
        setError('No auth token found.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [examId, subjectId]);
  

  useEffect(() => {
    fetchTokenAndCoinNum();
    fetchData();
  }, [fetchTokenAndCoinNum, fetchData]);

  const handleTestClick = (testId, isLocked, isExamTest) => {
    if (isLocked) return;
    if (isExamTest) {
      setSelectedExamTestId(testId);
      setShowSuccessAlert(true);
    } else {
      setClickedTest(testId);
      navigate(`/questions/${examId}/${subjectId}/${testId}`);
    }
  };

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">Something went wrong. Please check your internet or try again later.<button onClick={() => navigate(-1)} className="back-button">
    <FaArrowLeft className="white-arrow" />
    </button></div>;
  }

  if (!tests || tests.length === 0) {
    return <div className="no-data">COMING SOON!!!<button onClick={() => navigate(-1)} className="back-button">
    <FaArrowLeft className="white-arrow" />
    </button></div>;
  }

  return (
    <div className="test-levels-page">
     
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />
      </button>
      
        <h1>Levels</h1>
        
   
      <div className="tests-container" ref={flatListRef}>
        {tests.map((test, index) => (
          <div
            key={index}
            className={`main-test-item ${test.lock ? 'locked' : ''} ${test.id === clickedTest ? 'clicked' : ''}`}
            onClick={() => handleTestClick(test.id, test.lock, test.isExamTest)}
          >
            {test.lock ? (
              <img src={Lock} alt="Locked" />
            ) : (
              <span className='levelname'>{test.isExamTest ? `Ex${test.level}` : test.level}</span>
            )}
          </div>
        ))}
      </div>
      
      <ExamBeginAlertPage
        visible={showSuccessAlert}
        imageSource={examImageUri}
        title="CONGRATULATIONS!"
        onClose={() => {
          setShowSuccessAlert(false);
          navigate(`/Exam/${examId}/${subjectId}/${selectedExamTestId}`);
        }}
        onCancel={() => {
          setShowSuccessAlert(false);}}
      />
    </div>
  );
};

export default TestLevelsPage;
