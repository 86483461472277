import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../css/AdminNewsCountryPage.css"; // Add a CSS file for styles
import { API_URL } from "../config";
import { FaArrowLeft } from 'react-icons/fa';

const AdminNewsCountryPage = () => {
  const [countries, setCountries] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    try {
      const response = await axios.get(`${API_URL}/admin/countries/`);
      setCountries(response.data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const navigateToNews = (countryId) => {
    navigate(`/admin-news-list/${countryId}`);
  };

  return (
    <div className="container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1 className="header-title">News Countries</h1>
      <div className="countries-list">
        {countries.map((country) => (
          <div
            key={country.id}
            className="country-card"
            onClick={() => navigateToNews(country.id)}
          >
            {country.flag && (
              <img src={country.flag} alt={country.name} className="country-flag" />
            )}
            <p className="country-name">{country.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminNewsCountryPage;