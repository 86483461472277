import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UTMEimage from '../assets/images/POST.jpg'
import "../css/HomePage.css"; 
import { API_URL } from "../config";
import Ads1Component from "../ads/Ads1";

const HomePage = () => {
  const navigate = useNavigate();
  const [exams, setExams] = useState([]);
  const isValidJson = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch {
      return false;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchTokenAndData = async () => {
      try {
        const tokenString = localStorage.getItem("authToken");

        if (tokenString && isValidJson(tokenString)) {
          const token = JSON.parse(tokenString);

          const examsResponse = await fetch(`${API_URL}/exams/`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token.access}`,
              "Content-Type": "application/json",
            },
          });

          if (examsResponse.ok) {
            const examsData = await examsResponse.json();
            console.log("Exams:", examsData);

            // Filter out exams that are postUTME
            const filteredExams = examsData.exams.filter(exam => !exam.is_postUTME);
            setExams(filteredExams);
          } else {
            console.error("Error fetching exams:", examsResponse.statusText);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchTokenAndData();
  }, [navigate]);

  const handleNavigateToSubjects = (examId) => {
    navigate(`/subjects/${examId}`);
  };

  const handleNavigateToInstitution = () => {
    navigate("/institutions"); // Adjust the path as needed for the institution page
  };

  return (
    <div className="home-screen">
      <div className="ads-container">
        <Ads1Component />
      </div>

      <div className="content">
        {exams && exams.length > 0 ? (
          <div className="exam-list">
            {exams.map((exam) => (
              <div
                key={exam.id}
                className="exam-card"
                onClick={() => handleNavigateToSubjects(exam.id)}
              >
                <div className="exam-logo">
                  <img
                    src={exam.exam_img}
                    alt={exam.name}
                    className="exam-image"
                  />
                </div>
                <div className="exam-name">{exam.name}</div>
              </div>
            ))}

            {/* New card to navigate to the institution page */}
            <div
              className="exam-card"
              onClick={handleNavigateToInstitution}
            >
              <div className="exam-logo">
                {/* You can put any placeholder image or icon here */}
                <img
                  src={UTMEimage } // Replace with your image path
                  alt="Institution"
                  className="exam-image"
                />
              </div>
              <div className="exam-name">ADMISSION-TEST</div>
            </div>
          </div>
        ) : (
          <p>No exams available.</p>
        )}
      </div>
    </div>
  );
};

export default HomePage;

