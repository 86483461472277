import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../css/AdminEditTestOptionPage.css';
import { API_URL } from '../config';
import { FaArrowLeft } from 'react-icons/fa';

export default function AdminEditTestOptionPage() {
  const { optionId } = useParams();
  const navigate = useNavigate();

  const [optionLetter, setOptionLetter] = useState('');
  const [text, setText] = useState('');
  const [isAnswer, setIsAnswer] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    fetch(`${API_URL}/admin/option/${optionId}/`)
      .then((response) => response.json())
      .then((data) => {
        setOptionLetter(data.option_letter);
        setText(data.text);
        setIsAnswer(data.is_answer);
        setImageUrl(data.option_img);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [optionId]);

  const updateOption = () => {
    fetch(`${API_URL}/admin/option/${optionId}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ optionLetter, text, isAnswer }),
    })
      .then(() => {
        console.log('Option updated successfully');
        navigate(-1);
      })
      .catch((error) => {
        console.error('Error updating option:', error);
      });
  };

  const toggleIsAnswer = () => {
    setIsAnswer((prevState) => !prevState);
  };

  return (
    <div className="container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1>Edit Option</h1>

      <label className="label">Option Letter</label>
      <select
        className="dropdown"
        value={optionLetter}
        onChange={(e) => setOptionLetter(e.target.value)}
      >
        <option value="">Select Option Letter</option>
        <option value="A">Option A</option>
        <option value="B">Option B</option>
        <option value="C">Option C</option>
        <option value="D">Option D</option>
      </select>

      <label className="label">Option Text</label>
      <textarea
        className="textarea"
        placeholder="Enter option text"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />

      <div className="toggle-container">
        <input
          type="checkbox"
          checked={isAnswer}
          onChange={toggleIsAnswer}
          className="checkbox"
        />
        <label className="toggle-label">Is Answer</label>
      </div>

      {imageUrl && (
        <div className="image-preview-container">
          <img src={imageUrl} alt="Option Preview" className="image-preview" />
        </div>
      )}

      <button className="editbutton" onClick={updateOption}>
        Update Option
      </button>
    </div>
  );
}
