import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../css/AdminPromoCodePage.css";
import { FaArrowLeft } from 'react-icons/fa';
import { API_URL } from "../config";

const AdminPromoCodePage = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPromoCodeData();
  }, []);

  const fetchPromoCodeData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/admin/promo-code-user-count/`);
      const sortedData = response.data.sort((a, b) => b.profit - a.profit);
      setData(sortedData);
    } catch (error) {
      console.error("Error fetching promo codes:", error);
    }
    setLoading(false);
  };

  const updatePromoCodes = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/admin/update_promo_codes/`);
      if (response.data.status === "success") {
        alert("Promo codes updated successfully");
        fetchPromoCodeData(); // Refresh the data after the update
      } else {
        alert("Failed to update promo codes");
      }
    } catch (error) {
      alert("An error occurred while updating promo codes");
      console.error(error);
    }
    setLoading(false);
  };

  const navigateToPromoInstanceDate = () => {
    navigate("/admin-promo-instance");
  };

  return (
    <div className="promo-container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <button className="navigate-button" onClick={navigateToPromoInstanceDate}>
        DATES
      </button>
      <button className="update-button" onClick={updatePromoCodes} disabled={loading}>
        {loading ? "Updating..." : "Update Promo Codes"}
      </button>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="promo-list">
          {data.map((item) => (
            <div key={item.promo_code} className="promo-item">
              <p className="user-text">Username: {item.user}</p>
              <p className="promo-code-text">Promo Code: {item.promo_code}</p>
              <p className="count-text">User Count: {item.user_count}</p>
              <p className="profit-text">User Profit: {item.profit}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AdminPromoCodePage;
