import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../css/AdminTestPage.css'; // Assuming you have CSS for styling
import { API_URL } from '../config';
import { FaArrowLeft } from 'react-icons/fa';

const TestItem = React.memo(({ item, navigateToQuestion, editTest}) => (
  <div className="test-item">
    <button className="test-button" onClick={() => navigateToQuestion(item.id)}>
      Level: {item.level}
    </button>
    <button className="edit-button" onClick={() => editTest(item.id)}>Edit</button>
    <button className="delete-button" >Delete</button>
  </div>
));

export default function AdminTestPage() {
  const navigate = useNavigate();
  const { subjectId } = useParams();
  const [tests, setTests] = useState([]);
  const [level, setLevel] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredTests, setFilteredTests] = useState([]);

  const fetchTests = useCallback(() => {
    fetch(`${API_URL}/admin/tests/${subjectId}/`)
      .then(response => response.json())
      .then(data => {
        setTests(data);
        setFilteredTests(data);
      })
      .catch(() => alert('Failed to fetch tests.'));
  }, [subjectId]);

  useEffect(() => {
    fetchTests();
  }, [fetchTests]);

  const addTest = () => {
    if (!level) {
      alert('Test level is required.');
      return;
    }

    setLoading(true);

    fetch(`${API_URL}/admin/tests/${subjectId}/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ level }),
    })
      .then(response => response.json())
      .then(newTest => {
        setTests([newTest, ...tests]);
        setFilteredTests([newTest, ...tests]);
        setLevel('');
      })
      .catch(() => alert('Failed to add test.'))
      .finally(() => setLoading(false));
  };

  const editTest = useCallback((testId) => {
    navigate(`/admin-edit-test/${testId}`);
  }, [navigate]);

  // const deleteTest = useCallback((testId) => {
  //   if (window.confirm('Are you sure you want to delete this test level?')) {
  //     fetch(`${API_URL}/admin/test/${testId}/`, {
  //       method: 'DELETE',
  //     })
  //       .then(() => {
  //         const updatedTests = tests.filter(test => test.id !== testId);
  //         setTests(updatedTests);
  //         setFilteredTests(updatedTests);
  //       })
  //       .catch(() => alert('Failed to delete test.'));
  //   }
  // }, [tests]);

  const navigateToQuestion = useCallback((testId) => {
    navigate(`/admin-test-question/${testId}`);
  }, [navigate]);

  const handleSearch = useCallback((query) => {
    setSearchQuery(query);
    if (query === '') {
      setFilteredTests(tests);
    } else {
      setFilteredTests(tests.filter(test => test.level && test.level.toString().includes(query)));
    }
  }, [tests]);

  return (
    <div className="admin-container">
      <button onClick={() => navigate(-1)} className="back-button">
      <FaArrowLeft className="white-arrow" />Back
      </button>
      <h1>Manage Test</h1>
      <input
        placeholder="Test Level"
        value={level}
        onChange={(e) => setLevel(e.target.value)}
        className="text-input"
        type="number"
      />
      <button className="add-button" onClick={addTest} disabled={loading}>
        {loading ? 'Adding...' : 'Add Level'}
      </button>
      <input
        placeholder="Search"
        value={searchQuery}
        onChange={(e) => handleSearch(e.target.value)}
        className="text-input"
      />
      <div className="test-list">
        {filteredTests.map(item => (
          <TestItem
            key={item.id}
            item={item}
            navigateToQuestion={navigateToQuestion}
            editTest={editTest}
            
          />
        ))}
      </div>
    </div>
  );
}
