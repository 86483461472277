import React, { useRef } from 'react';
import '../css/LandingPage.css';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
  const playStoreSection = useRef(null);
  const navigate = useNavigate();

  const getDownloadLink = () => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
  
    if (userAgent.includes("Win32") || userAgent.includes("Win64")) {
      return "https://github.com/UC-BLACK1/Liteupper-WAEC-Desktop/releases/latest/download/LiteUpper.0.1.0.exe";
    } else if (userAgent.includes("Mac")) {
      if (userAgent.includes("ARM") || userAgent.includes("aarch64")) {
        return "https://github.com/UC-BLACK1/Liteupper-WAEC-Desktop/releases/latest/download/LiteUpper-0.1.0-arm64.dmg"; // Apple Silicon
      } else {
        return "https://github.com/UC-BLACK1/Liteupper-WAEC-Desktop/releases/latest/download/LiteUpper-0.1.0-x86_64.dmg"; // Intel Mac (if available)
      }
    } else if (userAgent.includes("Linux") || platform.includes("Linux")) {
      if (userAgent.includes("Ubuntu") || userAgent.includes("Debian") || platform.includes("x86_64")) {
        return "https://github.com/UC-BLACK1/Liteupper-WAEC-Desktop/releases/latest/download/liteupper-desktop_0.1.0_amd64.deb";
      } else {
        return "https://github.com/UC-BLACK1/Liteupper-WAEC-Desktop/releases/latest/download/LiteUpper-0.1.0.AppImage";
      }
    } else {
      return "https://github.com/UC-BLACK1/Liteupper-WAEC-Desktop/releases/latest"; // Default to GitHub releases page
    }
  };
  

  return (
    <div className="landing-page">
      <div className="slideshow">
        <h1>Welcome to LiteUpper</h1>
        <p>Your ultimate app to prepare for external exams with past questions and answers.</p>
      </div>

      <button className="cta-button" onClick={() => navigate('/login')}>
        Get Started
      </button>

      <section ref={playStoreSection} className="play-store">
          <h2>Get the App</h2>
          <p>Download LiteUpper from the Google Play Store or App Store today and start preparing for success!</p>
          <a 
            href="https://play.google.com/store/apps/details?id=com.liteupper.liteupper" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <img 
              src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg" 
              alt="Google Play Store" 
              className="play-store-image" 
            />
          </a>
      </section>
      <section className="desktop-download">
        <h2>Download for Desktop</h2>
        <p>Install LiteUpper on Windows, macOS, or Linux with one click.</p>
        
        <a href={getDownloadLink()} target="_blank" rel="noopener noreferrer">
          <button className="download-button">
            Download for Desktop
          </button>
        </a>
      </section>

      <div className="content-section">
        <section className="about">
          <h2>About LiteUpper</h2>
          <p>
            LiteUpper is a revolutionary platform designed to empower students preparing for external exams. 
            We provide a comprehensive collection of past questions and answers across various subjects, 
            helping you study smart and achieve your academic goals.
          </p>
        </section>

        <section className="goals">
          <h2>Our Goals</h2>
          <ul>
            <li>To make exam preparation accessible and effective for all students.</li>
            <li>To provide curated resources that align with various exam boards.</li>
            <li>To help students build confidence and improve their success rates.</li>
          </ul>
        </section>

        <section className="future">
          <h2>Our Future Vision</h2>
          <p>
            At LiteUpper, we envision becoming the leading global platform for exam preparation. 
            We aim to expand our resources to include interactive study tools, video tutorials, and real-time quizzes. 
            Our future includes advanced AI-driven analytics to tailor study plans for every user.
          </p>
        </section>

        <section className="customer-service">
          <h2>Customer Service</h2>
          <p>
            Your success is our priority. Our dedicated customer support team is here to assist you with any 
            issues or inquiries. Reach out to us via email at <a href="mailto:liteupper@gmail.com">liteupper@gmail.com</a>, 
            and we'll respond promptly.
          </p>
        </section>

        
      </div>
    </div>
  );
};

export default LandingPage;

