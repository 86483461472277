import React from 'react';
import '../../css/GoldErrorAlertPage.css'; // Create a CSS file for styles

import goldIcon from '../../assets/icons/gold.png';

const GoldErrorAlertPage = ({ visible, title, buyGold, onClose, coinNum }) => {
  if (!visible) return null;

  return (
    <div className="Goldmodal-overlay">
      <div className="Goldmodal-background">
        <div className="Goldalert-container">
          <div className="Goldalert-card">
            <h2 className="goldalert-title">{title}</h2>
            
              <div className="gold-display">
                <img src={goldIcon} alt="Gold" className="gold-icon" />
                <span className="gold-text">{coinNum}</span>
              </div>
            
            <p className="Goldalert-message">Click on buy now</p>
          </div>
          <div className="Goldalert-actions">
            <button className="cancel-button" onClick={onClose}>
              Cancel
            </button>
            <button className="buy-now-button" onClick={buyGold}>
              Buy Now
            </button>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoldErrorAlertPage;
