import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation in React Router
import '../css/PromoCodePage.css'; // Assume you have a CSS file for styling
import { API_URL } from '../config';
import Logo from '../assets/icons/LiteIcon.png'
import { FaArrowLeft } from 'react-icons/fa';

const PromoCodePage = () => {
  
  const navigate = useNavigate();

  const [promoCodeData, setPromoCodeData] = useState({
    promo_code: {
      school: '',
      bank_name: '',
      account_name: '',
      account_num: '',
      code: '',
      profit: 0,
    },
    promo_instances: [],
  });

  const [editing, setEditing] = useState(false);
  const [authToken, setAuthToken] = useState('');
  

  useEffect(() => {
    const retrieveAuthToken = async () => {
      try {
        const tokenString = localStorage.getItem('authToken');
        if (tokenString) {
          const token = JSON.parse(tokenString);
          setAuthToken(token);
          fetchPromoCodeData(token);
          fetchStudentProfile(token);
        }
      } catch (error) {
        console.error('Error retrieving token:', error);
      }
    };

    retrieveAuthToken();
  }, []);

  const fetchPromoCodeData = async (token) => {
    try {
      const response = await fetch(`${API_URL}/promo-code/`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token.access}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setPromoCodeData({
          promo_code: data.promo_code || {
            school: '',
            bank_name: '',
            account_name: '',
            account_num: '',
            code: '',
            profit: 0,
          },
          promo_instances: data.promo_instances || [],
        });
      
      } else {
        console.error('Failed to fetch promo code data');
      }
    } catch (error) {
      console.error('Error fetching promo code data:', error);
    }
  };

  const fetchStudentProfile = async (token) => {
    try {
      const response = await fetch(`${API_URL}/student/profile/`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token.access}` },
      });

      if (response.ok) {
        
       
      } else {
        console.error('Error fetching student profile:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching student profile:', error);
    }
  };

  const handleUpdate = async () => {
    const { school, bank_name, account_name, account_num, code } = promoCodeData.promo_code;

  // Validation: Check if any field is empty
  if (!school || !bank_name || !account_name || !account_num || !code) {
    alert('Please fill in all the fields before saving.');
    return;
  }
    try {
      const response = await fetch(`${API_URL}/promo-code/`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${authToken.access}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(promoCodeData),
      });

      if (response.ok) {
        
        navigate('/profile');
      } else {
        const data = await response.json();
        if (data && data.error) {
          alert(data.error);
        } else {
          alert('Failed to update promo code. Please try again later.');
        }
      }
    } catch (error) {
      console.error('Error updating promo code:', error);
      alert('Failed to update promo code. Please try again later.');
    }
  };

  return (
    <div className="promo-code-screen">
      <button onClick={() => navigate(-1)} className="back-button">
        <FaArrowLeft className="white-arrow" />
       
      </button>
      <header className="promo-header">
        <h1>Promo Code</h1>
       
      </header>

      <main className="promo-content">
        <div className="promo-details">
          <img src={Logo} alt="LiteUp Icon" className="liteup-icon" />
          <h2>Last Profit</h2>
          <div className="profit-list">
            {promoCodeData.promo_instances.map((instance, index) => (
              <div key={index} className="profit-item">
                ₦{instance.profit}
              </div>
            ))}
          </div>

          <h2>Promo Code Details</h2>
          <div className="promo-field">
            <label>Profit</label>
            <p>₦{promoCodeData.promo_code.profit || 0}</p>
          </div>
          <div className="promo-field">
            <label>Promocode</label>
            <input type="text" value={promoCodeData.promo_code.code} readOnly />
          </div>
          <div className="promo-field">
            <label>School</label>
            <input
              type="text"
              value={promoCodeData.promo_code.school}
              onChange={(e) =>
                setPromoCodeData({
                  ...promoCodeData,
                  promo_code: { ...promoCodeData.promo_code, school: e.target.value },
                })
              }
              disabled={!editing}
            />
          </div>
          <div className="promo-field">
            <label>Bank Name</label>
            <input
              type="text"
              value={promoCodeData.promo_code.bank_name}
              onChange={(e) =>
                setPromoCodeData({
                  ...promoCodeData,
                  promo_code: { ...promoCodeData.promo_code, bank_name: e.target.value },
                })
              }
              disabled={!editing}
            />
          </div>
          <div className="promo-field">
            <label>Account Name</label>
            <input
              type="text"
              value={promoCodeData.promo_code.account_name}
              onChange={(e) =>
                setPromoCodeData({
                  ...promoCodeData,
                  promo_code: { ...promoCodeData.promo_code, account_name: e.target.value },
                })
              }
              disabled={!editing}
            />
          </div>
          <div className="promo-field">
            <label>Account Number</label>
            <input
              type="text"
              value={promoCodeData.promo_code.account_num}
              onChange={(e) =>
                setPromoCodeData({
                  ...promoCodeData,
                  promo_code: { ...promoCodeData.promo_code, account_num: e.target.value },
                })
              }
              disabled={!editing}
            />
          </div>

          <button className='promo-button' onClick={() => (editing ? handleUpdate() : setEditing(true))}>
            {editing ? 'Save' : 'Edit'}
          </button>
        </div>
      </main>
    </div>
  );
};

export default PromoCodePage;
