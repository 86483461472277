import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Snackbar } from '@mui/material';
import { API_URL } from '../config';
import liteIcon from '../assets/icons/LiteIcon.png';
import authImage from '../assets/images/AuthImg.png';
import { FaArrowLeft } from 'react-icons/fa';
import '../css/LoginPage.css';

const RegisterPage = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Register - LiteUpper';
  }, []);

  const handleRegister = async () => {
    setError('');
    setFieldErrors({});
    setLoading(true);

    if (!username || !email || !password || !confirmPassword || password !== confirmPassword) {
      const newErrors = {};
      if (!username) newErrors.username = 'Username is required';
      if (!email) newErrors.email = 'Email is required';
      if (!password) newErrors.password = 'Password is required';
      if (!confirmPassword) newErrors.confirm_password = 'Confirm Password is required';
      if (password && confirmPassword && password !== confirmPassword)
        newErrors.confirm_password = 'Passwords do not match';

      setFieldErrors(newErrors);
      setLoading(false);
      return;
    }

    try {
      await axios.post(`${API_URL}/create-user/`, {
        username,
        email,
        password,
        confirm_password: confirmPassword,
      });

      navigate('/verification');
    } catch (err) {
      console.error(err);

      if (err.response && err.response.status === 400 && err.response.data) {
        const apiErrors = err.response.data;

        // Convert backend errors like { password: ["Ensure this field..."] }
        const formatted = {};
        Object.keys(apiErrors).forEach((field) => {
          formatted[field] = Array.isArray(apiErrors[field])
            ? apiErrors[field][0]
            : apiErrors[field];
        });

        setFieldErrors(formatted);
      } else {
        setError('Something went wrong. Please try again.');
      }
    }

    setLoading(false);
  };

  const handleLoginNavigation = () => {
    navigate('/login');
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="login-container">
      <div className="image-container">
        <img src={authImage} alt="Auth Background" className="auth-image" />
        <div className="overlay"></div>
      </div>

      <div className="form-container">
        <div className="login-card">
          <button onClick={handleBackClick} className="back-button">
            <FaArrowLeft className="white-arrow" />
          </button>

          <img src={liteIcon} alt="LiteUpper Logo" className="logo" />

          <input
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="input"
          />
          {fieldErrors.username && <div className="error-text">{fieldErrors.username}</div>}

          <input
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="input"
          />
          {fieldErrors.email && <div className="error-text">{fieldErrors.email}</div>}

          <input
            placeholder="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input"
          />
          {fieldErrors.password && <div className="error-text">{fieldErrors.password}</div>}
          {password && (
            <div
              className={`password-hint ${
                password.length === 0
                  ? 'black'
                  : password.length < 6
                  ? 'red'
                  : 'green'
              }`}
            >
              Ensure this field has at least 6 characters
            </div>
          )}

          <input
            placeholder="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="input"
          />
          {fieldErrors.confirm_password && (
            <div className="error-text">{fieldErrors.confirm_password}</div>
          )}

          <button onClick={handleRegister} disabled={loading} className="login-button">
            {loading ? 'Registering...' : 'Register'}
          </button>

          <Snackbar
            open={!!error}
            autoHideDuration={6000}
            onClose={() => setError('')}
            message={error}
          />
        </div>

        <button onClick={handleLoginNavigation} className="register-button">
          Already have an account? Login
        </button>
      </div>
    </div>
  );
};

export default RegisterPage;
