import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PaystackButton } from 'react-paystack'; // Use react-paystack library for web integration
import { API_URL } from '../config';

const PaystackPaymentScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { amount, coins, email, secretkey, publickey } = location.state || {};
  const [authToken, setAuthToken] = useState(null);
  const [currency, setCurrency] = useState('USD'); // Default currency
 

  useEffect(() => {
    const retrieveAuthToken = () => {
      try {
        const tokenString = localStorage.getItem('authToken');
        if (tokenString) {
          const token = JSON.parse(tokenString);
          setAuthToken(token);
          fetchUserProfile(token);
        }
      } catch (error) {
        console.error('Error retrieving token:', error);
      }
    };

    const fetchUserProfile = async (token) => {
      try {
        const response = await fetch(`${API_URL}/user/country/`, {
          headers: { Authorization: `Bearer ${token.access}` },
        });

        if (response.ok) {
          const data = await response.json();
          setCurrency(data.country.currency);
        } else {
          console.error('Failed to fetch user profile.');
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    retrieveAuthToken();
  }, []);

  const updateUserCoins = async () => {
    if (!authToken) return;

    try {
      const response = await fetch(`${API_URL}/update-coins/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${authToken.access}` },
        body: JSON.stringify({ coins, amount }),
      });

      if (response.ok) {
        alert(`Payment Successful! You have received ${coins} Golds`);
        navigate('/home');
      } else {
        console.error('Failed to update user coins.');
      }
    } catch (error) {
      console.error('Error updating user coins:', error);
    }
  };

  const componentProps = {
    email,
    amount: amount * 100, // Convert amount to kobo for Paystack
    currency,
    publicKey: publickey,
    secretkey: secretkey,
    text: 'make payment',
    onSuccess: async (res) => {
      console.log('Payment successful:', res);
      await updateUserCoins();
    },
    onClose: () => {
      alert('Payment Cancelled');
      navigate('/golds');
    },
  };

  

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5',
      }}
    >
      {currency ? (
        <div
          style={{
            backgroundColor: '#fff',
            padding: '30px',
            borderRadius: '12px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            width: '300px',
            textAlign: 'center',
            fontFamily: 'Arial, sans-serif',
          }}
        >
          <h3 style={{ marginBottom: '20px' }}>Payment Details</h3>
          <div style={{ marginBottom: '10px' }}>
            <strong>Current Currency:</strong> {currency}
          </div>
          <div style={{ marginBottom: '10px' }}>
            <strong>Amount:</strong> {amount}
          </div>
          <div style={{ marginBottom: '20px' }}>
            <strong>Number of Golds:</strong> {coins}
          </div>

          {/* Proceed Button */}
          <button style={styles.proceedButton}>
            
          

          {/* Paystack Button */}
          
            <PaystackButton {...componentProps} style={styles.paystackButton} />
         
          </button>
          <div style={{ marginTop: '20px' }}>
            {/* Cancel Button */}
            <button
              onClick={() => navigate('/golds')}
              style={styles.cancelButton}
            >
              Cancel Payment
            </button>
          </div>
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <div>Loading...</div>
        </div>
      )}
    </div>
  );
};

const styles = {
  paystackButton: {
    backgroundColor: '#32CD32',
    color: '#fff',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '16px',
    width: '100%',
    marginBottom: '15px',
  },
  cancelButton: {
    backgroundColor: '#FF6347',
    color: '#fff',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '16px',
    width: '100%',
  },
  proceedButton: {
    backgroundColor: '#32CD32',
    color: '#fff',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '16px',
    width: '50%',
    marginBottom: '15px',
  },
};

export default PaystackPaymentScreen;
