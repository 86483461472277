import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import '../css/AdminSubjectCategoryEditPage.css'; // Assuming CSS is managed separately
import { API_URL } from '../config';
import { FaArrowLeft } from 'react-icons/fa';

const AdminSubjectCategoryEditPage = () => {
  const navigate = useNavigate();
  const { categoryId } = useParams(); // This will hold the category ID as a string
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch category details by ID when the component mounts
    axios
      .get(`${API_URL}/admin/subjectcategory/${categoryId}/`)
      .then((response) => {
        setName(response.data.name);
      })
      .catch((err) => {
        console.error('Failed to fetch category details:', err);
        setError('Failed to fetch category details. Please try again.');
      });
  }, [categoryId]);

  const updateCategory = () => {
    setLoading(true);
    axios
      .put(`${API_URL}/admin/subjectcategory/${categoryId}/`, { name })
      .then(() => {
        
        navigate(-1); // Navigate back
      })
      .catch((err) => {
        console.error('Failed to update category:', err);
        alert('Failed to update category. Please try again.');
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="admin-edit-container">
      <button onClick={() => navigate(-1)} className="back-button">
        <FaArrowLeft className="back-button-icon" /> Back
      </button>
      <h1>Edit Category</h1>
      {error && <p className="error-message">{error}</p>}
      <input
        className="admin-input"
        type="text"
        placeholder="Enter category name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <button
        className="admin-button"
        onClick={updateCategory}
        disabled={loading}
      >
        {loading ? 'Updating...' : 'Update Category'}
      </button>
    </div>
  );
};

export default AdminSubjectCategoryEditPage;
